import secureLocalStorage from "react-secure-storage";
import { UserInfo } from "../redux/UserInfoReducer";
import { useNavigate } from "react-router-dom";


export const getToken = () : string | number | true | false | object | null => {
  const localStorageToken = getTokenFromLocalStorage();
  if (localStorageToken) {
    return localStorageToken;
  }
  return false;
}

export const getUserInfo = (): UserInfo | false => {
    // Check if the user is in Redux state
    const reduxUser = getFromRedux();
  
    if (reduxUser) {
      return reduxUser;
    }
  
    // If not in Redux, check local storage
    const localStorageUser = getFromLocalStorage();
  
    if (localStorageUser) {
      // Update Redux state with user information for future access
      updateReduxState(localStorageUser);
      return localStorageUser;
    }
  
    // If not in Redux or local storage, return false
    return false;
  };
  
  const getFromRedux = (): UserInfo | null => {
    // Implement logic to get user info from Redux state
    // Example: return yourReduxState.user;
    return null;
  };
  
  const getFromLocalStorage = (): UserInfo | null => {
    // Implement logic to get user info from local storage
    const token = secureLocalStorage.getItem('token');

    if (token) {
      try { 
        var userInfoObject = secureLocalStorage.getItem('user');
        if (typeof userInfoObject === 'string'){
          const user = JSON.parse(userInfoObject) as UserInfo;
          return user;
        }
      } catch (error) {
        console.error('Error parsing user information:', error);
      }
    }
  
    return null;
  };


  const getTokenFromLocalStorage = (): string | number | true | object | null => {
    // Implement logic to get user info from local storage
    const token = secureLocalStorage.getItem('token');

    if (token) {
      return token;
    }
  
    return null;
  };
  
  const updateReduxState = (user: UserInfo): void => {
    // Implement logic to update Redux state with user information
    // Example: dispatch(updateUserInfo(user));
  };

export const useLogout = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    secureLocalStorage.removeItem("token");
    secureLocalStorage.removeItem("user");

    // You might want to dispatch actions here if using Redux
    // dispatch(removeToken());
    // dispatch(removeUserInfo());

    navigate("/login");
  };

  return handleLogout;
};