import React, {  useState } from 'react'
import { MuiFileInput } from 'mui-file-input'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import DeleteIcon from "@mui/icons-material/Delete";
import SortIcon from "@mui/icons-material/ArrowDownward";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
    TextField,
    MenuItem,
    FormControl,
    InputLabel,
    Button,
    Grid,
    IconButton,
    Box, Alert, AlertTitle, Fade

} from "@mui/material";
import { ProductsImages, ProductsImagesMandatory } from '../../models/Products';
import { UploadFile, deleteImagesById, getImageslistByProdId } from '../../api/Products.Api';
import DataTable, { TableColumn } from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/Store";

interface ProductDetails {
    id: number;
}

const ProductImages: React.FC<ProductDetails> = ({ id }) => {
    const [alertVisibility, setAlertVisibility] = useState(false);
    const [file, setFile] = React.useState(null)
    const acceptedImageTypes = ".png, .jpeg";
    const acceptedFileTypes = ".pdf";
    const [url, setUrl] = useState<any | null>(null);
    const [type, setType] = useState("");
    const [pending, setPending] = React.useState(true);
    const [imageForm, setImageForm] = useState<ProductsImages>({
        id: 0,
        type: "",
        title: "",
        url: ""
    });
    const [imageList, setImageList] = useState<ProductsImages[]>([{
        id: 0,
        title: "",
        type: "",
        url: ""
    }]);
    const loggedInUser = useSelector((state: RootState) => state.user);
    const [isRequired, setIsRequried] = useState<ProductsImagesMandatory>({
        title: false,
        type: false,
        file: false,
    });


    const handleChangeFile = (newFile: any) => {
        setFile(newFile)
        if (imageForm.type === "Image") {
            getBase64(newFile);
        }
        else {
            setUrl("");
        }
    }
    const loadImagesList = async (type:string) => {
        var images = await getImageslistByProdId(id, loggedInUser.token);
        if (images.length > 0) {
            var imgList = images.filter(imgList => imgList.type == type)
            setImageList(imgList);
        }
        setPending(false);
    }
    const handleChangeDropdown = (e: SelectChangeEvent<string>) => {
        const { name, value } = e.target;
        setImageForm((prevData: any) => ({
            ...prevData,
            [name as string]: value,
        }));
        setFile(null);
        setType(value);
        loadImagesList(value);
    };
    const handleChange = (
        e: React.ChangeEvent<{ name?: string; value: unknown }>
    ) => {
        const { name, value } = e.target;
        setImageForm((prevData) => ({
            ...prevData,
            [name as string]: value as string, // Ensure value is treated as a string
        }));
    };
    const handleSaveImages = async () => {
        var required = new ProductsImagesMandatory();
        var isvalidation = true;
        if (imageForm.title === "") {
            required.title = true;
            isvalidation = false;
        }
        if (imageForm.type === "") {
            required.type = true;
            isvalidation = false;
        }
        if (!file) {
            required.file = true;
            isvalidation = false;
        }
        if (!isvalidation) {
            setIsRequried(required);
            return false;

        }
        const response = await UploadFile(id, imageForm.title, imageForm.type, file, loggedInUser.token);
        if (response.status == "200") {
            if (response.data.affectedRows > 0) {
                setAlertVisibility(true);
                var reset = new ProductsImages();
                reset.title = '';
                reset.type = '';
                setFile(null);
                setImageForm(reset);
                setUrl('');
            }
        }
    }
    function getBase64(file: any) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            console.log(reader.result);
            setUrl(reader.result);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    const handleDelete = (item: any) => {
        deleteImage(Number(item.id))

    };

    const removeImagelistById = (imgList: ProductsImages[], id: number) =>
        setImageList(imgList.filter(img => img.id !== id));

    const deleteImage = async (id: number) => {
        const response = await deleteImagesById(id, loggedInUser.token);
        if (response.status == "200") {
            removeImagelistById(imageList, id);
        }

    }
    const columns: TableColumn<ProductsImages>[] = [
        {
            name: "Id",
            selector: (row) => row.id,
            sortable: true,
            width: "80px"
        },
        {
            name: "Title",
            selector: (row) => row.title,
            sortable: true
        },
        {
            name: "Type",
            selector: (row) => row.type,
            sortable: true,
        },
        {
            name: "Image",
            cell: row => (
                (row.type == "Image") ?
                    <img
                        src={row.url}
                        alt='player'
                        width='84px'
                        height='56px'
                    />
                    :
                    <a href={row.url} target='_blank' rel="noopener"><AttachFileIcon></AttachFileIcon></a>
            )
        },

        {
            name: "Actions",
            cell: (row) => (
                <div className="action-buttons">
                    <IconButton color="error" onClick={() => handleDelete(row)}>
                        <DeleteIcon />
                    </IconButton>
                </div>
            ),
            ignoreRowClick: true,
        },
    ];
    return (
        <Box >
            <p>Choose from the list below which kind of media you would like to manage:</p>
            <Grid container spacing={3}>
                <Grid item sm={12} md={3}>
                    <FormControl fullWidth>
                        <InputLabel id="type-label">File Type</InputLabel>
                        <Select
                            variant="outlined"
                            labelId="type-label"
                            label={"File Type"}
                            name="type"
                            id="type"
                            value={imageForm.type}
                            onChange={handleChangeDropdown}
                            required
                            error={imageForm.type == "" && isRequired.type == true}
                        >
                            <MenuItem key="Image" value="Image">
                                Image
                            </MenuItem>
                            <MenuItem key="Document" value="Document">
                                Document
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sm={12} md={3}>
                    <TextField
                        label="Title"
                        name="title"
                        value={imageForm.title}
                        onChange={handleChange}
                        fullWidth
                        required
                        hidden={imageForm.type === "" ? true : false}
                        error={imageForm.title === "" && isRequired.title === true}
                    />
                </Grid>
                <Grid item sm={12} md={3}>
                    <MuiFileInput value={file} fullWidth hidden={imageForm.type === "" ? true : false} onChange={handleChangeFile} hideSizeText placeholder="File Upload" error={isRequired.file === true} InputProps={{
                        inputProps: {
                            accept: imageForm.type === "Image" ? acceptedImageTypes : acceptedFileTypes
                        },
                        startAdornment: <AttachFileIcon />
                    }}
                    />
                </Grid>
                <Grid item sm={12} md={3}>
                    <Button
                        hidden={imageForm.type === "" ? true : false}
                        variant="contained"
                        style={{ height: "55px", width: "120px" }}
                        onClick={handleSaveImages}
                    >
                        Upload
                    </Button>
                </Grid>
            </Grid>
            <Box>
                <Grid item sm={12} md={8}>
                    {imageForm.type == "Image" ?
                        <div style={{ overflow: "hidden", padding: "10px" }}>
                            <img src={url}/>
                        </div> : ''
                    }
                </Grid>
            </Box>
            <Box sx={{ paddingTop: 10 }}>
                <Grid container spacing={3} justifyContent="left" sm={12} md={3}>
                    <Fade
                        in={alertVisibility} //Write the needed condition here to make it appear
                        timeout={{ enter: 1000, exit: 1000 }} //Edit these two values to change the duration of transition when the element is getting appeared and disappeard
                        addEndListener={() => {
                            setTimeout(() => {
                                setAlertVisibility(false)
                            }, 2000);
                        }}
                    >
                        <Alert severity="success" variant="standard" className="alert">
                            <AlertTitle>Saved Successfull</AlertTitle>
                        </Alert>
                    </Fade>
                </Grid>
            </Box>
            <Box hidden={imageForm.type === "" ? true : false}>
                <div style={{ padding: "0", width: "100%" }}>
                    <DataTable
                        title="Files List"
                        columns={columns}
                        data={imageList}
                        sortIcon={<SortIcon />}
                        pagination
                        progressPending={pending}
                    />
                </div>
            </Box>
        </Box>
    );
}

export default ProductImages;