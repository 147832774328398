import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import logo from "../../assets/ependisis-logo-no-outline.png";
import "./HeaderMenu.css";
import { useEffect, useRef, useState } from "react";
import { getUserInfo, useLogout } from "../../auth/AuthUtils";
import { UserInfo } from "../../redux/UserInfoReducer";
import { PersonCircle } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

function HeaderMenu() {
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const handleLogout = useLogout();

  const handleAdminPage = () => {
    console.log("Accessing Admin Page");
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      const user = getUserInfo();
      if (user) {
        setUserInfo(user);
      }
    };
    fetchUserInfo();
  }, []);

  return (
    <div className="overlay-container">
      <Navbar collapseOnSelect expand="lg" variant="dark" className="mh-104">
        <div className="mx-auto logo">
          {" "}
          {/* Center the content */}
          <a href="/">
            <div className="logo">
              <img src={logo} alt="Logo" />
            </div>
          </a>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        </div>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto navbar-options">
            <Nav.Link href="/buyers">Buyers</Nav.Link>
            <Nav.Link href="/sellers">Sellers</Nav.Link>
            <Nav.Link href="#contactus">Contact Us</Nav.Link>
          </Nav>
          <Nav className="ml-auto ai-center">
            {userInfo === null ? (
              <div className="join-now-btn responsive-link-container">
                <Nav.Link href="/login" id="join-link" className="btn">
                  Log in
                </Nav.Link>
              </div>
            ) : (
              <NavDropdown
                title={<PersonCircle size={30} style={{ cursor: "pointer" }} />}
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item disabled>
                  Hello, {userInfo.name}
                </NavDropdown.Item>
                <NavDropdown.Divider />
                {userInfo?.roles === "Admin" ? (
                  <NavDropdown.Item as={Link} to="/admin/dashboard" onClick={handleAdminPage}>
                    Admin Page
                  </NavDropdown.Item>
                ) : (
                  <></>
                )}
                <NavDropdown.Item onClick={handleLogout}>
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default HeaderMenu;
