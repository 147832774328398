import React, { useEffect, useState } from "react";
import { RootState } from "../../../redux/Store";
import { useSelector } from "react-redux";
import CustomerDocument from './CustomerDocument';
import CustomerQuestion from './CustomerQuestion';
import ProductDocumentsApproval from './ProductDocumentsApproval';
import { useSearchParams } from "react-router-dom";
import Spinner from "../../components/Spinner";
import moment from 'moment';
import {
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Button,
    Grid,
    SelectChangeEvent,
    Box, Typography, Tab, Tabs, Alert, AlertTitle, Fade

} from "@mui/material";
import { GetAllCustomerById, UpdateCustomer, SendeEmailForStatus, SendeEmailToAdmin } from '../../api/Customer.Api';
import { CustomersData } from "../../models/Customers";
import { Height } from "@mui/icons-material";
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}


function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component={'div'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const Customers: React.FC = () => {
    const [loading, setLoading] = React.useState(true);
    const loggedInUser = useSelector((state: RootState) => state.user);
    const [value, setValue] = React.useState(0);
    const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const [userId, setUserId] = useState<number>(0);
    const [alertVisibility, setAlertVisibility] = useState(false);
    const [isSuccessMsg, setIsSuccessMsg] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    let [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams.size > 0) {
            let userId = searchParams.get('id');
            handleCustomerById(Number(userId));
        }
    }, []);
    const [formData, setFormData] = useState<CustomersData>({
        id: 0,
        name: "",
        address: "",
        city: "",
        postcode: "",
        country: "",
        email: "",
        phone: "",
        status: "",
        created_date: "",
        roles: "",
        package: "",
        companyName: "",
        lastLogin: "",
        companyRegister: "",
        position: "",
        rolesId: "",
        documentAccess: false
    });



    const handleCustomerById = async (userID: number) => {
        setLoading(true);
        const response = await GetAllCustomerById(loggedInUser.token, userID);
        if (response.status == "200") {
            let customer = response.data;
            const utcDate = new Date(customer.created_date); // UTC date
          const localDate = utcDate.toLocaleString();
          const convertedDateTime = moment(localDate, 'MM/DD/YYYY HH:mm').format('DD/MM/YYYY HH:mm');
            setFormData({
                id: customer.id,
                name: customer.name,
                address: customer.address,
                city: customer.city,
                postcode: customer.postcode,
                country: customer.country,
                email: customer.email,
                phone: customer.phone,
                status: customer.status,
                created_date: convertedDateTime,
                roles: customer.roles,
                package: customer.package,
                companyName: customer.companyName,
                lastLogin: customer.lastLogin,
                companyRegister: customer.companyRegister,
                position: customer.position,
                rolesId: `${customer.rolesId}`,
                documentAccess: customer.documentAccess
            });
            setUserId(userID);
        }
        setLoading(false);

    }
    const handleSaveCustomer = async () => {
        setLoading(true);
        const response = await UpdateCustomer(loggedInUser.token, formData);
        if (response.status == "200") {
            if (formData.status === "Approved" || formData.status == "Blocked") {
                await SendeEmailForStatus(loggedInUser.token, userId,formData.status.toLocaleLowerCase());
                await SendeEmailToAdmin(loggedInUser.token, userId);
            }
            setAlertVisibility(true);
            setIsSuccessMsg(true);
        }
        else {
            setErrorMsg(response.data.errors)
            setIsSuccessMsg(false);
        }
        setLoading(false);

    }

    const handleChange = (
        e: React.ChangeEvent<{ name?: string; value: unknown }>
    ) => {
        const { name, value } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [name as string]: value as string, // Ensure value is treated as a string
        }));
    };


    const handleChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [name as string]: e.target.checked,
        }));
    };

    const handleChangeDropdown = (e: SelectChangeEvent<string>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name as string]: value,
        }));
    };


    return (

        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChangeTabs} aria-label="basic tabs example">
                    <Tab label="Details" {...a11yProps(0)} />
                    <Tab label="Questions" {...a11yProps(1)} disabled={userId > 0 ? false : true} />
                    <Tab label="Documents" {...a11yProps(2)} disabled={userId > 0 ? false : true} />
                    <Tab label="Product Document Approval" {...a11yProps(3)} disabled={userId > 0 ? false : true} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                {loading ?
                    <Spinner /> : ""}
                <Grid container spacing={3}>
                    <Grid item sm={12} md={4}>
                        <TextField
                            label="Name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid item sm={12} md={4}>
                        <TextField
                            label="Email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid item sm={12} md={4}>
                        <TextField
                            label="Phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <TextField
                            label="Address"
                            name="address"
                            value={formData.address}
                            onChange={handleChange}
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <TextField
                            label="Postcode"
                            name="postcode"
                            value={formData.postcode}
                            onChange={handleChange}
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <TextField
                            label="City"
                            name="city"
                            value={formData.city}
                            onChange={handleChange}
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <TextField
                            label="Country"
                            name="country"
                            value={formData.country}
                            onChange={handleChange}
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel id="roles-lable">Roles</InputLabel>
                            <Select
                                name="rolesId"
                                id="rolesId"
                                onChange={handleChangeDropdown}
                                value={formData.rolesId}
                                labelId="roles-lable"
                                label="Roles"
                            >

                                <MenuItem key="2" value="2">
                                    Buyer
                                </MenuItem>
                                <MenuItem key="3" value="3">
                                    Seller
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel id="package-label">Package</InputLabel>
                            <Select
                                name="package"
                                id="package"
                                onChange={handleChangeDropdown}
                                value={formData.package}
                                labelId="package-label"
                                label="Package"
                            >

                                <MenuItem key="1" value="1">
                                    Basic
                                </MenuItem>
                                <MenuItem key="2" value="2">
                                    Premium
                                </MenuItem>
                                <MenuItem key="3" value="3">
                                    Enterprise
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sm={12} md={4}>
                        <TextField
                            label="Company Name"
                            name="companyName"
                            value={formData.companyName}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={12} md={4}>
                        <TextField
                            label="Company Registeration"
                            name="companyRegister"
                            value={formData.companyRegister}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={12} md={4}>
                        <TextField
                            label="Position"
                            name="position"
                            value={formData.position}
                            onChange={handleChange}
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid item sm={12} md={4}>
                        <FormControl fullWidth>
                            <InputLabel id="status-label">Status</InputLabel>
                            <Select
                                name="status"
                                id="status"
                                onChange={handleChangeDropdown}
                                value={formData.status}
                                required
                                labelId="status-label"
                                label="Status"
                            >

                                <MenuItem key="PendingVerification" value="PendingVerification">
                                    PendingVerification
                                </MenuItem>
                                <MenuItem key="PendingDetails" value="PendingDetails">
                                    PendingDetails
                                </MenuItem>
                                <MenuItem key="PendingDocuments" value="PendingDocuments">
                                    PendingDocuments
                                </MenuItem>
                                <MenuItem key="PendingApproval" value="PendingApproval">
                                    PendingApproval
                                </MenuItem>
                                <MenuItem key="Approved" value="Approved">
                                    Approved
                                </MenuItem>
                                <MenuItem key="Blocked" value="Blocked">
                                    Blocked
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sm={12} md={4}>
                        <TextField
                            label="CreatedDate"
                            name="created_date"
                            value={formData.created_date}
                            onChange={handleChange}
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid item sm={12} md={4}>
                        <TextField
                            label="Last Login"
                            name="lastLogin"
                            value={formData.lastLogin}
                            onChange={handleChange}
                            fullWidth
                            disabled
                        />
                    </Grid>
                    {/* <Grid item sm={12} md={6}>
                        <FormControlLabel control={<Checkbox checked={formData.documentAccess} onChange={handleChangeCheckbox} name="documentAccess" />} label="Document Access" />
                    </Grid> */}

                </Grid>
                <Box sx={{ paddingTop: 5 }}>
                    <Grid container spacing={3}>
                        <Grid item md={2}>
                            <Button
                                style={{height: "56px"}}
                                variant="contained"
                                onClick={handleSaveCustomer}
                                fullWidth
                            >
                                Save
                            </Button>
                        </Grid>

                        <Grid item md={2}>
                            <Fade
                                in={alertVisibility} //Write the needed condition here to make it appear
                                timeout={{ enter: 1000, exit: 1000 }} //Edit these two values to change the duration of transition when the element is getting appeared and disappeard
                                addEndListener={() => {
                                    setTimeout(() => {
                                        setAlertVisibility(false)
                                    }, 4000);
                                }}
                            >
                                {isSuccessMsg === true ?
                                    <Alert severity="success" variant="standard" className="alert">
                                        <AlertTitle>Success</AlertTitle>
                                    </Alert> :
                                    <Alert severity="error" variant="standard" className="alert">
                                        <AlertTitle>{errorMsg}</AlertTitle>
                                    </Alert>
                                }
                            </Fade>
                        </Grid>
                    </Grid>
                </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <CustomerQuestion id={userId} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <CustomerDocument id={userId} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
                <ProductDocumentsApproval id={userId} />
            </CustomTabPanel>
        </Box >

    );
};

export default Customers;