import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function Spinner() {
    return (
        <div className='loader-admin'>
            <Box sx={{ display: 'flex',top:'50%',left:'50%',zIndex:'-2',position:'fixed'}}>
                <CircularProgress />
            </Box>
        </div>
    );
}