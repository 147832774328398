import { Col, Container, Row } from "react-bootstrap";
import ContactUs from "../components/ContactUs/ContactUs";
import PlanCardContainerNew from "../containers/PlanCardContainerNew";
import HeaderMenu from "../components/HeaderMenu/HeaderMenu";
import Footer from "../components/Footer/Footer";
import QuestionAnswerSingle from "../interfaces/components/QuestionAnswerTool/QuestionAnswerSingle";
import QuestionAnswerTool from "../components/QuestionAnswerTool/QuestionAnswerTool";
import SellerIdea from "../components/SellerIdea/SellerIdea";

const questionAnswer: QuestionAnswerSingle[] = [
  {
    question: "Is there a listing fee?",
    answer:
      "Answer 1. No, you list for free and then only pay a flat 4% closing fee when you get an opportunity. If you don’t sell your business (we’re confident you will), you don’t pay a thing.",
  },
  {
    question: "Do I pay a fee if I can't sell my opportunity?",
    answer:
      "Answer 2. No, you list for free and then only pay a flat 4% closing fee when you get an opportunity. If you don’t sell your business (we’re confident you will), you don’t pay a thing.",
  },
  {
    question: "Who pays for escrow?",
    answer:
      "Answer 3. No, you list for free and then only pay a flat 4% closing fee when you get an opportunity. If you don’t sell your business (we’re confident you will), you don’t pay a thing.",
  },
  {
    question:
      "Do I need professional services when selling with Ependisis.com?",
    answer:
      "Answer 4. No, you list for free and then only pay a flat 4% closing fee when you get an opportunity. If you don’t sell your business (we’re confident you will), you don’t pay a thing.",
  },
  {
    question: "How does Ependisis.com help me sell?",
    answer:
      "Answer 5. No, you list for free and then only pay a flat 4% closing fee when you get an opportunity. If you don’t sell your business (we’re confident you will), you don’t pay a thing.",
  },
];

const sellersIdeas = [
  {
    title: "Deal Origination And Aggregation",
    ideas: [""],
  },
  {
    title: "Investor Matching and Facilitation",
    ideas: [""],
  },
  {
    title: "Transparent deal insights and analytics",
    ideas: [""],
  },  
  {
    title: "Personalized Portfolio Diversification",
    ideas: [""],
  },
];

const PlanCardData = [
  {
    title: "Flat Listings",
    titleColor: "#FFFFFF",
    backgroundColor: "#636FA0",
    subtitle: "Listing guidance",
    buttonText: "Sign up",
    bulletList: [
      {
        title: "What you need to know:",
        bullets: [
          "Legal and Compliance Due Diligence",
          "Listing cost on website",
          "Coordinating meetings as an observer",
        ],
      },
    ],
  },
  {
    title: "Managed Services",
    titleColor: "#FFFFFF",
    backgroundColor: "#272F53",
    subtitle: "Acquisition & Process Management",
    buttonText: "Sign up",
    bulletList: [
      {
        title: "What you need to know:",
        bullets: [
          "Legal and Marketing help",
          "Vetted matchmaking",
          "Data room preparation",
          "Due Diligence and escrow for offers received",
          "Coordination of roadshows and meetings",
        ],
      },
    ],
  },
  {
    title: "Transaction Fee",
    titleColor: "#FFFFFF",
    backgroundColor: "#636FA0",
    subtitle: "Assistance along the process",
    buttonText: "Sign up",
    bulletList: [
      {
        title: "What you need to know:",
        bullets: [
          "The transaction fee applies to both scenarios regardless of listing plan chosen",
          "Ependisis.com retains the option, by exception, to convert part of the 5% into a hybrid version between cash and equity",
        ],
      },
    ],
  },
];

function SellersNew() {
  return (
    <Container fluid>
      <Row
        className="justify-content-center w-100"
        style={{ backgroundColor: "#101324" }}
      >
        <HeaderMenu />
      </Row>
      <Row
        style={{
          backgroundColor: "#FFFFFF",
          marginTop: "30px",
        }}
        className="justify-content-md-center"
      >
        <h2 className="row-title h-center ">
          Manage and close your deals faster
        </h2>
      </Row>
      {/* Packages boxes      */}
      <Row
        style={{
          backgroundColor: "#FFFFFF",
          paddingBottom: "80px",
          marginTop: "30px",
        }}
        className="justify-content-md-center"
      >
        <PlanCardContainerNew data={PlanCardData} roleSelected="3" />
      </Row>
      {/*
      <Row
        style={{
          backgroundColor: "#FFFFFF",
          paddingBottom: "50px",
          marginTop: "20px",
        }}
        className="justify-content-md-center"
      >
        <h2 className="row-subtitle h-center ">
          Whether it’s your first acquisition or you invest for a living, we’ve
          got the right plan for you.
        </h2>
      </Row>
      <Row
        style={{
          backgroundColor: "#FFFFFF",
          paddingBottom: "110px",
          marginTop: "20px",
        }}
        className="justify-content-md-center"
      >
        <QuestionAnswerTool qas={questionAnswer} />
      </Row>*/}
      <Row
        className="justify-content-center"
        style={{ marginLeft: "8%", marginRight: "1%", marginTop: "1%" }}
      >
        <Col style={{ paddingRight: "5%" }}>
          <Row>
            <h2 className="home-caption" style={{color:"#272F53", fontSize: "70px", lineHeight:"60px"}}>Our</h2>
            <h2 className="home-caption" style={{color:"#272F53", fontSize: "70px", lineHeight:"60px"}}>Services</h2>
          </Row>
          <Row>
            <p className="home-subcaption"> Empower your success</p>
            <p className="home-paragraph" style={{ marginTop: "3%", fontSize:"20px" }}>
              At Ependisis, we offer a comprehensive range of services designed
              to cater to the unique needs of our discerning investors and
              sellers. With our expertise and cutting-edge platform, we ensure
              that you have access to the best deals and opportunities in the
              private equity landscape.
            </p>
          </Row>
        </Col>
        <Col>
          <div>
            {sellersIdeas.map((ideaSet, index) => (
              <SellerIdea
                key={index}
                title={ideaSet.title}
                ideas={ideaSet.ideas}
              />
            ))}
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center" style={{ marginTop: "5%" }}>
        <ContactUs />
      </Row>
      <Row
        className="justify-content-center"
        style={{ marginLeft: "1%", marginRight: "1%", marginTop: "1%" }}
      >
        <Footer />
      </Row>
    </Container>
  );
}

export default SellersNew;
