export class UpdateCustomerRequest {
    id: number;
    address: string;
    city: string;
    postcode: string;
    country: string;
    phone: string;
    company: string;
    position:string;
  
    constructor(data: {
      id: number,
      address: string,
      city: string,
      postcode: string,
      country: string,
      phone: string,
      company: string,
      position: string,
    }) {
      this.id = data.id;
      this.address = data.address;
      this.city = data.city;
      this.postcode = data.postcode;
      this.country = data.country;
      this.phone = data.phone;
      this.company = data.company;
      this.position = data.position;
    }
  }