import React, { useEffect, useState } from "react";
import {
  Button,
  Carousel,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { Fade } from "@mui/material";
import Alert from "react-bootstrap/Alert";

import "../../styles/Signup.css";
import SignInPicture from "../../assets/taylor-white.png";
import { useDispatch } from "react-redux";
import logo from "../../assets/logo-black-letters.png";
import secureLocalStorage from "react-secure-storage";
import {
  Register as EpendisisRegister,
  GenerateVerification as EpendisisGenVerify,
} from "../../apis/EpendisisApi";
import { RegisterRequest } from "../../interfaces/models/RegisterRequest";
import { addToken, addUserInfo } from "../../redux/UserInfoReducer";
import { RegisterResponse } from "../../interfaces/models/RegisterResponse";
import { useNavigate } from "react-router-dom";

const SignupOld: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userPackage, setUserPackage] = useState<string>("");
  const [userRole, setUserRole] = useState<string>("");
  const [showModal, setShowModal] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [alertVisibility, setAlertVisibility] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleModalShow = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    switch (name) {
      case "firstName":
        setFirstName(value);
        break;
      case "lastName":
        setLastName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "password":
        setPassword(value);
        break;
      default:
        break;
    }
  };

  const handleApiCall = async () => {
    setLoading(true);
    try {
      const response = await EpendisisRegister(
        new RegisterRequest(
          `${firstName} ${lastName}`,
          email,
          password,
          userPackage,
          userRole
        )
      );
      if (response.status == "200") {
        let registerRes: RegisterResponse = response.data;
        secureLocalStorage.setItem("token", registerRes.token);
        secureLocalStorage.setItem("user", JSON.stringify(registerRes.user));
        dispatch(addToken(registerRes.token));
        dispatch(addUserInfo(JSON.stringify(registerRes.user)));
        if (registerRes.user.roles == "Admin") {
          navigate("/admin/dashboard");
        } else {
          try {
            const verifyResponse = await EpendisisGenVerify(
              email,
              registerRes.token
            );
          } catch {}
          navigate("/onboarding");
        }
      } else {
        setErrorMsg(response.data.msg);
        setAlertVisibility(true);
      }
    } catch (error: any) {
      setErrorMsg(error?.response?.data?.msg || "An unexpected error occurred");
      setAlertVisibility(true);
      console.error("Error making API call:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleApiCall();
  };

  useEffect(() => {
    document.title = "Signup";
    const fetchDataFromLocalStorage = () => {
      try {
        const user = secureLocalStorage.getItem("user");
        const token = secureLocalStorage.getItem("token");
        if (user !== null && token !== null) {
          navigate("/onboarding");
        }
      } catch (error) {}

      try {
        // Retrieve values from secureLocalStorage
        const storedRole = secureLocalStorage.getItem("role");
        const storedPackage = secureLocalStorage.getItem("package");

        // Dispatch actions or handle data as needed
        if (storedRole === null || storedPackage === null) {
          setShowModal(true);
        } else {
          setUserPackage(storedPackage.toString());
          setUserRole(storedRole.toString());
        }
      } catch (error) {
        console.error("Error fetching data from secureLocalStorage:", error);
      }
    };

    fetchDataFromLocalStorage();
  }, [dispatch]);

  return (
    <div className="login-page">
      <Container fluid>
        <Row
          style={{
            display: "flex",
            minHeight: "100%",
          }}
        >
          <Col xs={12} md={4} lg={4} style={{ paddingLeft: "4%" }}>
            <div className="d-flex flex-column align-items-center justify-content-center h-100">
              <Row className="mr-auto">
                <div>
                  <a href="/">
                    <div className="logo" style={{ marginBottom: "5%" }}>
                      <img src={logo} alt="Logo" />
                    </div>
                  </a>
                </div>
              </Row>
              <Row className="pl-4 mr-auto" style={{ marginBottom: "1%" }}>
                <h3 className="login-heading">Join our Brand!</h3>
              </Row>
              <Row className="mr-auto" style={{ paddingLeft: "4.5%" }}>
                <p className="login-subheading">
                  The Premier Marketplace for Private Equity and M&A investments
                </p>
              </Row>
              <Form
                onSubmit={handleSubmit}
                style={{
                  marginRight: "auto",
                  marginLeft: "4%",
                  width: "90%",
                  marginTop: "2%",
                  marginBottom: "4%",
                }}
              >
                <Fade
                  in={alertVisibility}
                  timeout={{ enter: 1000, exit: 1000 }}
                  addEndListener={() => {
                    setTimeout(() => {
                      setAlertVisibility(false);
                    }, 5000);
                  }}
                >
                  <Alert key="danger" variant="danger">
                    {errorMsg}
                  </Alert>
                </Fade>
                <Form.Group
                  controlId="login-email"
                  style={{ marginBottom: "2%" }}
                >
                  <Form.Label className="login-label">First Name</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="firstName"
                      value={firstName}
                      onChange={handleInputChange}
                      placeholder="First Name"
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group
                  controlId="login-email"
                  style={{ marginBottom: "7%" }}
                >
                  <Form.Label className="login-label">Last Name</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="lastName"
                      value={lastName}
                      onChange={handleInputChange}
                      placeholder="Last Name"
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group
                  controlId="login-email"
                  style={{ marginBottom: "2%" }}
                >
                  <Form.Label className="login-label">Email address</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-envelope"
                        viewBox="0 0 16 16"
                      >
                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                      </svg>
                    </InputGroup.Text>
                    <Form.Control
                      type="email"
                      name="email"
                      value={email}
                      onChange={handleInputChange}
                      placeholder="Enter email"
                    />
                  </InputGroup>
                  <Form.Text
                    className="text-muted"
                    style={{ fontStyle: "italic" }}
                  >
                    This should be an email you can access after acquisition
                  </Form.Text>
                </Form.Group>
                <Form.Group controlId="password" style={{ marginBottom: "5%" }}>
                  <Form.Label className="login-label">Password</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-lock"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z" />
                      </svg>
                    </InputGroup.Text>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      onChange={handleInputChange}
                      name="password"
                      value={password}
                      minLength={8}
                    />
                  </InputGroup>
                  <Form.Text
                    className="text-muted"
                    style={{ fontStyle: "italic" }}
                  >
                    Must include at least 8 characters
                  </Form.Text>
                </Form.Group>
                <Button
                  variant="primary"
                  type="submit"
                  className="login-signin"
                  disabled={loading} // Disable the button when loading is true
                >
                  {loading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      &nbsp;
                      Loading...
                    </>
                  ) : (
                    "Sign up"
                  )}
                </Button>
              </Form>
              <p className="login-no-account">
                {" "}
                <span style={{ opacity: "0.5" }}>
                  Already have an account?{" "}
                </span>
                <a
                  href="./login"
                  style={{
                    color: "#536BC1",
                    fontWeight: "bold",
                    opacity: "1",
                    borderBottom: "1px solid #536BC1", // Add a border-bottom
                    paddingBottom: "2px", // Adjust the padding to move the underline lower
                    textDecoration: "none", // Remove the default underline
                  }}
                >
                  Log in
                </a>
              </p>
            </div>
          </Col>
          <Col xs={7} md={8} lg={8} className="d-none d-md-block">
            <Carousel className="h-100" indicators={false}>
              <Carousel.Item style={{ height: "100%" }}>
                <img
                  alt="Taylor White"
                  src={SignInPicture}
                  className="img-fluid"
                  style={{ height: "100%" }}
                />
                <Carousel.Caption>
                  <p className="login-carousel-paragraph">
                    "I had 15+ folks reach out to me on Acquire.com. I was
                    completely blown away by how easy it's been to reach these
                    prospective buyers. We weren't necessarily looking to sell
                    but Acquire.com has been A+. From start to finish, we were
                    acquired within 30 days at full asking price."
                  </p>
                  <h3 className="login-carousel-name">Taylor White</h3>
                  <p className="login-carousel-title">Founder, Amazon</p>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
        <Modal centered show={showModal} onHide={handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Missing Package</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Please choose one of the Buyer or Seller packages</p>
          </Modal.Body>
          <Modal.Footer>
            <a href="/buyers">
              <Button variant="primary">Buyer</Button>
            </a>
            <a href="/sellers">
              <Button variant="primary">Seller</Button>
            </a>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
};

export default SignupOld;
