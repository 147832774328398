import { Feature } from "./Feature";
import { ProductImage } from "./ProductImage";

export class ProductDetails {
    id: number;
    title: string;
    subTitle: string;
    isFavourite: string;
    descriptionShort: string;
    descriptionLong: string;
    terms: string;
    amount: number;
    location: string;
    dateAdded: Date;
    features: Feature[];
    images: ProductImage[];
    categoryName: string;
    categoryDescription: string;
    additionalInfo: string;
    metrics: Feature[];
    icon: string;
    documentStatus: number | null;

    constructor(data: any) {
        this.id = data.id;
        this.title = data.title;
        this.subTitle = data.subTitle;
        this.isFavourite = data.isFavourite;
        this.descriptionShort = data.descriptionShort;
        this.descriptionLong = data.descriptionLong;
        this.terms = data.terms;
        this.amount = parseFloat(data.amount);
        this.location = data.location;
        this.dateAdded = new Date(data.dateAdded);
        this.features = data.features.map((feature: any) => new Feature(feature));
        this.images = data.images.map((image: any) => new ProductImage(image));
        this.categoryName = data.categoryName;
        this.categoryDescription = data.categoryDescription;
        this.additionalInfo = data.additionalInfo;
        this.metrics = data.metrics.map((feature: any) => new Feature(feature));
        this.icon = data.icon;
        this.documentStatus = data.documentStatus;
    }
}