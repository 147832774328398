import React, { useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { RootState } from "../../../redux/Store";
import { useSelector } from "react-redux";
import { ProductDocumentApproval, DocumentStatus } from "../../models/Customers";
import EditIcon from "@mui/icons-material/Edit";
import { GetProductsApproval, UpdateProductsApproval } from '../../api/Customer.Api';
import DataTableFilter from "../../components/DataTableFilter";
import moment from 'moment';
import {
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Button,
    Grid,
    IconButton,
    Box, Alert, AlertTitle, Fade, Dialog, DialogActions, DialogContent, DialogContentText,
    DialogTitle, SelectChangeEvent
} from "@mui/material";
import Modal from 'react-bootstrap/Modal';

interface CustomerDetails {
    id: number;
}

const ProductDocumentsApproval: React.FC<CustomerDetails> = ({ id }) => {

    const [pending, setPending] = React.useState(true);
    const [openAlert, setOpenAlert] = React.useState(false);
    const loggedInUser = useSelector((state: RootState) => state.user);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [alertVisibility, setAlertVisibility] = useState(false);

    const [customerDocList, setCustomerDocList] = useState<ProductDocumentApproval[]>([{
        id: 0,
        customerId: 0,
        title: "",
        productId: 0,
        lastUpdate: "",
        status: DocumentStatus.Pending
    }]);
    const [formData, setFormData] = useState<ProductDocumentApproval>({
        id: 0,
        customerId: 0,
        title: "",
        productId: 0,
        lastUpdate: "",
        status: DocumentStatus.Pending
    });

    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
        false
    );
    const handleDocumentsList = async () => {
        const customerList = await GetProductsApproval(id, loggedInUser.token);
        if (customerList.status == "200") {
            setCustomerDocList(customerList.data);
            setPending(false);
        }
    }

    const colorCell = (value: string) => {
        if (value === "Pending") {
          return <div style={{ color: "orange", fontWeight:  "bold" }}>{value}</div>;
        } else if (value === "Approved") {
            return <div style={{ color: "#06bf06", fontWeight:  "bold" }}>{value}</div>;
        }   else if (value === "Blocked") {
            return <div style={{ color: "red", fontWeight:  "bold" }}>{value}</div>;
        }    else {
            return <div>{value}</div>;
          }    
      };

    useEffect(() => {
        handleDocumentsList();
    }, []);



    const handleEdit = (item: ProductDocumentApproval) => {
        setFormData(item);
        setShow(true);
    };

    const handleUpdateStatus = async () => {
        const customerList = await UpdateProductsApproval(formData.id, formData.status, loggedInUser.token);
        if (customerList.status == "200") {
            setAlertVisibility(true);
            handleDocumentsList();
            setShow(false)
        }

    };
    const handleAlertClickClose = () => {
        setOpenAlert(false);
    };
    const handleChangeDropdown = (e: SelectChangeEvent<number>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name as string]: value,
        }));
    };

    const convertDate = (utc:any) =>{
        const utcDate = new Date(utc); // UTC date
          const localDate = utcDate.toLocaleString();
          const convertedDateTime = moment(localDate, 'MM/DD/YYYY HH:mm').format('DD/MM/YYYY HH:mm');
          return convertedDateTime;
    }

    const columns: TableColumn<ProductDocumentApproval>[] = [
        {
            name: "Investment Title",
            selector: (row) => row.title,
            sortable: true
        },
        {
            name: "Request Status",
            selector: (row) => DocumentStatus[row.status],
            cell: (row) => colorCell(DocumentStatus[row.status]),
            sortable: true
        },
        {
            name: "Last Update",
            selector: (row) => convertDate(row.lastUpdate),
            sortable: true
        },
        {
            name: "Actions",
            cell: (row) => (
                <div className="action-buttons">
                    <IconButton color="primary" onClick={() => handleEdit(row)}>
                        <EditIcon />
                    </IconButton>
                </div>
            ),
            ignoreRowClick: true,
        },
    ];

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <DataTableFilter onFilter={(e: any) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} handleCreateNew="" addButtonName="" isShow="false" />
        );
    }, [filterText, resetPaginationToggle]);

    return (

        <div style={{ padding: "0", width: "100%" }}>
            <Dialog
                open={openAlert}
                onClose={handleAlertClickClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Action not allowed!
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        The feature/metrics cannot be deleted, it is already assigned to an investment! please delete the investment first before deleting features/metrics.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAlertClickClose}>Close</Button>
                </DialogActions>
            </Dialog>
            <Modal show={show} onHide={handleClose} size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Features</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Box>
                        <Grid container spacing={3}>
                            <Grid item sm={12} md={12}>
                                <FormControl>
                                    <InputLabel id="type-label">Type</InputLabel>
                                    <Select
                                        variant="outlined"
                                        labelId="type-label"
                                        label={"Status"}
                                        name="status"
                                        sx={{ width: 500 }}
                                        id="type"
                                        value={formData.status}
                                        onChange={handleChangeDropdown}
                                    >
                                        <MenuItem key="Finance" value="0">
                                            Pending
                                        </MenuItem>
                                        <MenuItem key="Metrics" value="1">
                                            Approved
                                        </MenuItem>
                                        <MenuItem key="Metrics" value="2">
                                            Blocked
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Box sx={{ paddingTop: 2 }} display={alertVisibility ? "" : "none"}>
                            <Grid container spacing={3} justifyContent="center">
                                <Grid item md={3}>
                                    <Fade
                                        in={alertVisibility} //Write the needed condition here to make it appear
                                        timeout={{ enter: 100, exit: 500 }} //Edit these two values to change the duration of transition when the element is getting appeared and disappeard
                                        addEndListener={() => {
                                            setTimeout(() => {
                                                setAlertVisibility(false)
                                            }, 1000);
                                        }}
                                    >
                                        <Alert severity="success" variant="standard" className="alert">
                                            <AlertTitle>Success</AlertTitle>
                                        </Alert>
                                    </Fade>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={handleClose} sx={{ paddingRight: 3 }}>Close</Button>
                    <Button
                        variant="contained"
                        onClick={handleUpdateStatus}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>
            <DataTable
                title="Investment's document permission List"
                columns={columns}
                data={customerDocList}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                persistTableHead
                progressPending={pending}
            />
        </div>
    )


}

export default ProductDocumentsApproval;