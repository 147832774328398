import React, { useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { GetCustomerDocumentByUser, DownloadDocuments } from '../../api/Customer.Api';
import { CusotmersDocument } from "../../models/Customers";
import { RootState } from "../../../redux/Store";
import { useSelector } from "react-redux";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import fileDownload from 'js-file-download';

import {
    IconButton,
} from "@mui/material";
interface CustomerDetails {
    id: number;
}

const CustomerDocument: React.FC<CustomerDetails> = ({ id }) => {

    const [pending, setPending] = React.useState(true);
    const loggedInUser = useSelector((state: RootState) => state.user);
    const [customerDocList, setCustomerDocList] = useState<CusotmersDocument[]>([{
        id: 0,
        customerId: 0,
        title: "",
        url: ""
    }]);

    useEffect(() => {
           handleCustomersDocuments();
    }, []);

    const handleCustomersDocuments = async () => {
        const docList = await GetCustomerDocumentByUser(loggedInUser.token, id);
        if (docList.status == "200") {
            setCustomerDocList(docList.data);
            setPending(false);
        }
    }
    const isSeparator = (value: string): boolean => value === '/' || value === '\\' || value === ':';
    const getExtension = (path: string): string => {
        for (let i = path.length - 1; i > -1; --i) {
            const value = path[i];
            if (value === '.') {
                if (i > 1) {
                    if (isSeparator(path[i - 1])) {
                        return '';
                    }
                    return path.substring(i + 1);
                }
                return '';
            }
            if (isSeparator(value)) {
                return '';
            }
        }
        return '';
    };
    const downloadFile = async (item: CusotmersDocument) => {
        var ext = getExtension(item.title);
        const down = {
            path: item.url,
            type: ext
        }
        const response = await DownloadDocuments(loggedInUser.token, down);
        if (response.status == "200") {
            if (ext === "jpg" || ext === "jpeg" || ext === "png") {

                const anchorElement = document.createElement('a');
                anchorElement.href = 'data:application/octet-stream;base64,' + response.data;;
                anchorElement.download = item.title;
                anchorElement.click();
            }
            else {
                fileDownload(response.data, item.title)
            }

        }
    }

    const columns: TableColumn<CusotmersDocument>[] = [
        {
            name: "Id",
            selector: (row) => row.id,
            sortable: true,
            width: "80px"
        },
        {
            name: "Title",
            selector: (row) => row.title,
            sortable: true
        },
        {
            name: "Actions",
            cell: (row) => (
                <div className="action-buttons">
                    <IconButton color="primary" onClick={() => downloadFile(row)}>
                        <FileDownloadIcon />
                    </IconButton>
                </div>
            ),
            ignoreRowClick: true,
        },
    ];

    return (


        <div style={{ padding: "0", width: "100%" }}>
            <DataTable
                title="Document List"
                columns={columns}
                data={customerDocList}
                pagination
                progressPending={pending}
            />
        </div>

    );
}


export default CustomerDocument;