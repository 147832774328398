import { Container } from "react-bootstrap";
import HomePropertyCardProps from "../../interfaces/components/HomePropertyCard/HomePropertyCardProps";

const HomePropertyCard: React.FC<HomePropertyCardProps> = ({
    title,
    titleColor,
    paragraph,
    paragraphColor,
  }) => {
    return (
      <Container>
        <hr style={{ height: '0.5px', borderTop:'4px solid #000000', opacity: "0.7" }} />
        <div
          style={{
            textAlign: 'left',
            fontFamily: 'Helvetica-Neue',
            fontWeight: 'medium',
            fontSize: '30px',
            lineHeight: '37px',
            letterSpacing: '0px',
            textTransform: 'uppercase',
            color: titleColor,
            opacity: 1,
            marginTop: '20px',
          }}
        >
          {title}
        </div>
        <div
          style={{
            textAlign: 'left',
            fontFamily: 'Helvetica-Neue-Light',
            fontWeight: 300,
            fontSize: '24px',
            lineHeight: '35px',
            letterSpacing: '0px',
            color: paragraphColor,
            opacity: 1,
            marginTop: '20px',
          }}
        >
          {paragraph}
        </div>
      </Container>
    );
  };
  
  export default HomePropertyCard;