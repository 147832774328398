import React, { useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { GetAllFeatures, CreateFeatures, DeleteFeatures, UpdateFeatures } from '../../api/Features.Api';
import { FeaturesData, FeaturesDataMadatory } from "../../models/features";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DataTableFilter from "../../components/DataTableFilter";
import { RootState } from "../../../redux/Store";
import { useDispatch, useSelector } from "react-redux";
import Modal from 'react-bootstrap/Modal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Cash, Coin, Bank, BarChart } from 'react-bootstrap-icons';
import {
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Button,
    Grid,
    SelectChangeEvent,
    IconButton,
    Box, Alert, AlertTitle, Fade

} from "@mui/material";

const FeaturesList: React.FC = () => {
    const [pending, setPending] = React.useState(true);
    const loggedInUser = useSelector((state: RootState) => state.user);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [featuresList, setFeaturesList] = useState<FeaturesData[]>([{
        id: 0,
        title: "",
        type: "",
        order: 0,
        icon: ""
    }]);

    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
        false
    );
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [icon, setIcon] = React.useState<string>('1');
    const [formData, setFormData] = useState<FeaturesData>({
        id: 0,
        title: "",
        type: "",
        order: 0,
        icon: ""
    });
    const [isRequired, setIsRequried] = useState<FeaturesDataMadatory>({
        title: false,
        type: false,
        order: false,
    });

    const [featureID, setFeatureID] = useState<number>(0);
    const [alertVisibility, setAlertVisibility] = useState(false);
    useEffect(() => {
        handleFeaturesList();
    }, []);


    const handleAlertClickOpen = () => {
        setOpenAlert(true);
    };

    const handleAlertClickClose = () => {
        setOpenAlert(false);
    };

    const handleIcon = (
        event: React.MouseEvent<HTMLElement>,
        newIcon: string,
    ) => {
        setIcon(newIcon);
    };


    const handleFeaturesList = async () => {
        const featuresList = await GetAllFeatures(loggedInUser.token);
        if (featuresList.status == "200") {
            let response: FeaturesData[] = featuresList.data;
            setFeaturesList(response);
            setPending(false);
        }
    }
    const handleEdit = (item: FeaturesData) => {
        setFeatureID(item.id);
        setIcon(item.icon);
        setFormData(item);
        setShow(true)
    };

    const handleChange = (
        e: React.ChangeEvent<{ name?: string; value: unknown }>
    ) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name as string]: value as string, // Ensure value is treated as a string
        }));
    };

    const removeFeaturelistById = (featList: FeaturesData[], id: number) =>
        setFeaturesList(featList.filter(featList => featList.id !== id));

    const handleDelete = async (item: any) => {
        let deletefeatures = await DeleteFeatures(loggedInUser.token, item.id);
        if (deletefeatures.status == "200") {
            removeFeaturelistById(featuresList, item.id)
        }
        else {
            handleAlertClickOpen();
        }
    }
    const handleAddFeatures = () => {
        var setTextbox = new FeaturesData();
        setTextbox.title = "";
        setTextbox.type = "";
        setTextbox.order = 0;
        setIcon('1');
        setFormData(setTextbox)
        setFeatureID(0);
        setShow(true)
    }

    const handleCreateFeature = async () => {
        var required = new FeaturesDataMadatory();
        var isvalidation = true;
        if (formData.title === "") {
            required.title = true;
            isvalidation = false;
        }
        if (formData.type === "") {
            required.type = true;
            isvalidation = false;
        }
        if (formData.order == null) {
            required.order = true;
            isvalidation = false;
        }
        if (!isvalidation) {
            setIsRequried(required);
            return false;

        }
        formData.icon = icon;

        let features = await CreateFeatures(loggedInUser.token, formData);
        if (features.status == "200") {
            setAlertVisibility(true);
            handleFeaturesList();
            var setTextbox = new FeaturesData();
            setTextbox.title = "";
            setTextbox.type = "";
            setTextbox.order = 0;
            setIcon('1');
            setFormData(setTextbox)
            setShow(false)
        }
    }

    const handleUpdateFeature = async () => {
        var required = new FeaturesDataMadatory();
        var isvalidation = true;
        if (formData.title === "") {
            required.title = true;
            isvalidation = false;
        }
        if (formData.type === "") {
            required.type = true;
            isvalidation = false;
        }
        if (formData.order == null) {
            required.order = true;
            isvalidation = false;
        }
        if (!isvalidation) {
            setIsRequried(required);
            return false;

        }
        formData.icon = "";

        let features = await UpdateFeatures(loggedInUser.token, formData);
        if (features.status == "200") {
            setAlertVisibility(true);
            handleFeaturesList();
            setShow(false)
            var setTextbox = new FeaturesData();
            setTextbox.title = "";
            setTextbox.type = "";
            setTextbox.order = 0;
            setFormData(setTextbox)
            setShow(false)

        }
    }
    const handleChangeDropdown = (e: SelectChangeEvent<string>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name as string]: value,
        }));
    };


    const columns: TableColumn<FeaturesData>[] = [
        {
            name: "Id",
            selector: (row) => row.id,
            sortable: true,
            width: "80px"
        },
        {
            name: "Title",
            selector: (row) => row.title,
            sortable: true
        },
        {
            name: "Type",
            selector: (row) => row.type,
            sortable: true,
        },
        {
            name: "Order",
            selector: (row) => row.order,
            sortable: true,
        },

        {
            name: "Actions",
            cell: (row) => (
                <div className="action-buttons">
                    <IconButton color="primary" onClick={() => handleEdit(row)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton color="error" onClick={() => handleDelete(row)}>
                        <DeleteIcon />
                    </IconButton>
                </div>
            ),
            ignoreRowClick: true,
        },
    ];

    const filteredItems = featuresList.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };
        return (
            <DataTableFilter onFilter={(e: any) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} handleCreateNew={handleAddFeatures} addButtonName="Add New" />
        );
    }, [filterText, resetPaginationToggle]);
    return (
        <div style={{ padding: "0", width: "100%" }}>
            <Dialog
                open={openAlert}
                onClose={handleAlertClickClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                Action not allowed!
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        The feature/metrics cannot be deleted, it is already assigned to an investment! please delete the investment first before deleting features/metrics.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAlertClickClose}>Close</Button>
                </DialogActions>
            </Dialog>
            <Modal show={show} onHide={handleClose} size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Features</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Box>
                        <Grid container spacing={3}>
                            <Grid item sm={12} md={12}>
                                <FormControl>
                                    <InputLabel id="type-label">Type</InputLabel>
                                    <Select
                                        variant="outlined"
                                        labelId="type-label"
                                        label={"Type"}
                                        name="type"
                                        sx={{ width: 500 }}
                                        id="type"
                                        value={formData.type}
                                        onChange={handleChangeDropdown}
                                        error={formData.type === "" && isRequired.type === true}>

                                        <MenuItem key="Finance" value="Finance">
                                            Finance
                                        </MenuItem>
                                        <MenuItem key="Metrics" value="Metrics">
                                            Metrics
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item sm={12} md={12}>
                                <TextField
                                    label="Title"
                                    name="title"
                                    value={formData.title}
                                    onChange={handleChange}
                                    sx={{ width: 500 }}
                                    required
                                    error={formData.title === "" && isRequired.title === true}
                                />
                            </Grid>

                            <Grid item sm={12} md={12}>
                                <TextField
                                    label="Order"
                                    name="order"
                                    value={formData.order}
                                    onChange={handleChange}
                                    sx={{ width: 500 }}
                                    required
                                    error={formData.order == null && isRequired.order === true}
                                />
                            </Grid>
                            <Grid item sm={12} md={12} display={formData.type ==="Metrics"?"block":"none"}>
                                <ToggleButtonGroup
                                    value={icon}
                                    exclusive
                                    onChange={handleIcon}
                                    aria-label="text alignment"

                                >
                                    <ToggleButton value="1" aria-label="left aligned">
                                        <Coin size={40}/>
                                    </ToggleButton>
                                    <ToggleButton value="2" aria-label="centered">
                                        <Bank size={40}/>
                                    </ToggleButton>
                                    <ToggleButton value="3" aria-label="right aligned">
                                        <Cash size={40}/>
                                    </ToggleButton>
                                    <ToggleButton value="4" aria-label="right aligned">
                                        <BarChart size={40}/>
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                        </Grid>
                        <Box sx={{ paddingTop: 2 }}>
                            <Grid container spacing={3} justifyContent="center">
                                <Grid item md={3}>
                                    <Fade
                                        in={alertVisibility} //Write the needed condition here to make it appear
                                        timeout={{ enter: 1000, exit: 1000 }} //Edit these two values to change the duration of transition when the element is getting appeared and disappeard
                                        addEndListener={() => {
                                            setTimeout(() => {
                                                setAlertVisibility(false)
                                            }, 4000);
                                        }}
                                    >
                                        <Alert severity="success" variant="standard" className="alert">
                                            <AlertTitle>Success</AlertTitle>
                                        </Alert>
                                    </Fade>
                                </Grid>
                            </Grid>
                        </Box>

                    </Box>
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={handleClose} sx={{ paddingRight: 3 }}>Close</Button>
                    {(featureID == 0) ?
                        <Button
                            variant="contained"
                            onClick={handleCreateFeature}>
                            Save
                        </Button>
                        :
                        <Button
                            variant="contained"
                            onClick={handleUpdateFeature}>
                            Update
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
            <DataTable
                title="Features/Metrics List"
                columns={columns}
                data={filteredItems}
                pagination
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                persistTableHead
                progressPending={pending}
            />
        </div>

    );
}

export default FeaturesList;
