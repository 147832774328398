import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './GetStartedHighlight.css';
import GetStartedHighlightProps from '../../interfaces/components/GetStartedHighlight/GetStartedHighlightProps';

const GetStartedHighlight: React.FC<GetStartedHighlightProps> = ({ enumeration, heading, paragraph }) => {
  return (
    <Container className="getstarted-highlight">
      <Row>
        <Col>
          <div className="getstarted-enumeration">{enumeration}</div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="getstarted-heading">{heading}</div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="getstarted-paragraph">{paragraph}</div>
        </Col>
      </Row>
    </Container>
  );
};

export default GetStartedHighlight;
