export class CustomersData {
  id: number;
  name: string;
  address: string;
  city: string;
  postcode: string;
  country: string;
  email: string;
  phone: string;
  status: string;
  created_date: string;
  roles: string;
  rolesId: string;
  package: string;
  companyName: string;
  lastLogin: string;
  companyRegister: string;
  position: string;
  documentAccess = false;
}

export class CusotmersDocument {
  id: number;
  customerId: number;
  title: string;
  url: string;
}

export class CustomerQuestions {
  id: number;
  question: string;
  answer: string;
}

export enum DocumentStatus {
  Pending = 0,
  Approved = 1,
  Blocked = 2,
}


export class ProductDocumentApproval {
  id: number;
  productId: number;
  customerId: number;
  status:DocumentStatus;
  title:string;
  lastUpdate:string;
  }
