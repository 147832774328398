
import React, { useEffect, useState } from 'react'
import { GetAllSellers, AssignProductToSeller, GetProductSellerById } from '../../api/Products.Api';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/Store";
import Spinner from "../../components/Spinner";
import Divider from '@mui/material/Divider';
import {
    TextField,
    Autocomplete,
    Box,
    Grid,
    Button, Fade, Alert, AlertTitle

} from "@mui/material";

interface ProductDetails {
    id: number;
}

interface SellerList {
    id: number;
    label: string
}
const Assignment: React.FC<ProductDetails> = ({ id }) => {

    const [loading, setLoading] = React.useState(true);
    useEffect(() => {
        handleDropdown();
    }, []);
    const loggedInUser = useSelector((state: RootState) => state.user);
    const [alertVisibilitySuccess, setAlertVisibilitySuccess] = useState(false);
    const [alertVisibilityError, setAlertVisibilityError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [sellerList, setSellerList] = useState<SellerList[]>([{
        id: 0,
        label: ""
    }]);
    const [value, setValue] = React.useState<SellerList | null>({ id: 0, label: "" });


    const handleDropdown = async () => {
        setLoading(true);
        const response = await GetAllSellers(loggedInUser.token);
        const sellers = await GetProductSellerById(id, loggedInUser.token);
        if (response.status == "200") {
            setSellerList(response.data);
        }
        if (sellers.status == "200") {
            if (sellers.data.length > 0) {
                setValue(sellers.data[0])
            }

        }
        setLoading(false);

    }

    const AssignProduct = async () => {
        setLoading(true);
        const response = await AssignProductToSeller(value?.id, id, loggedInUser.token);
        if (response.status == "200") {
            setAlertVisibilitySuccess(true);
        }
        else {
            setErrorMsg(response.data.errors)
            setAlertVisibilityError(true);
        }
        setLoading(false);

    }

    return (
        <Box >
            {loading ?
                <Spinner /> : ""}
            <Grid container spacing={3}>
                <Grid item sm={12} md={2}>
                    <Autocomplete
                        disablePortal
                        value={value}
                        id="combo-box-demo"
                        options={sellerList}
                        // sx={{ width: 300 }}
                        onChange={(event: any, newValue: SellerList | null) => {
                            setValue(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} label="Sellers" />}
                    />
                </Grid>
                <Grid item sm={12} md={1}>
                    <Button
                    style={{height:"56px"}}
                        variant="contained"
                        onClick={AssignProduct}
                    >
                        Assign
                    </Button>
                </Grid>
                <Grid item md={3}>
                    <Fade
                        in={alertVisibilitySuccess} //Write the needed condition here to make it appear
                        timeout={{ enter: 1000, exit: 1000 }} //Edit these two values to change the duration of transition when the element is getting appeared and disappeard
                        addEndListener={() => {
                            setTimeout(() => {
                                setAlertVisibilitySuccess(false)
                            }, 2000);
                        }}
                    >
                        <Alert severity="success" variant="standard" className="alert">
                            <AlertTitle>Success</AlertTitle>
                        </Alert>
                    </Fade>
                    <Fade
                        in={alertVisibilityError} //Write the needed condition here to make it appear
                        timeout={{ enter: 1000, exit: 1000 }} //Edit these two values to change the duration of transition when the element is getting appeared and disappeard
                        addEndListener={() => {
                            setTimeout(() => {
                                setAlertVisibilityError(false)
                            }, 4000);
                        }}
                    >
                        <Alert severity="error" variant="standard" className="alert">
                            <AlertTitle>{errorMsg}</AlertTitle>
                        </Alert>
                    </Fade>
                </Grid>
            </Grid>
            <Divider sx={{ marginTop:2, marginBottom: 2 }} />
        </Box>
    );
}


export default Assignment;