export class CategorysData {
    id: number;
    name: string;
    description: string;
    order: number;
  }

  export class CategorysDataMandatory {
    name: Boolean;
    description: Boolean;
    order: Boolean;
  }