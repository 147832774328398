import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import Footer from "../components/Footer/Footer";
import backgroundImg from "../assets/login-bg.png";
import HeaderMenu from "../components/HeaderMenu/HeaderMenu";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { addToken, addUserInfo } from "../redux/UserInfoReducer";
import secureLocalStorage from "react-secure-storage";
import { RegisterResponse } from "../interfaces/models/RegisterResponse";
import {
  Register as EpendisisRegister,
  GenerateVerification as EpendisisGenVerify,
  ForgetGenerateVerification,
} from "../apis/EpendisisApi";
import { RegisterRequest } from "../interfaces/models/RegisterRequest";

const ForgetPasswordEmail: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [alertVisibility, setAlertVisibility] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    switch (name) {
      case "email":
        setEmail(value);
        break;
    }
  };

  useEffect(() => {
    document.title = "Forget Password";
  }, []);

  const handleForgetPassword = async () => {
    try {
      const response = await ForgetGenerateVerification(email);
      if (response == "success") {
        navigate('/ForgetPasswordSuccess');
      } else {
        setErrorMsg("Invalid Account");
        setAlertVisibility(true);
      }
    } catch (error) {
      console.error("Error during password reset process:", error);
      setErrorMsg("Something went wrong. Please check the email address entered and try again later.");
      setAlertVisibility(true);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleForgetPassword();
  };

  return (
    <Container
      fluid
      className="wrapper"
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: "0",
      }}
    >
      <div
        style={{
          backgroundImage: `url(${backgroundImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "100vh",
          display: "grid",
          flexDirection: "column",
          paddingBottom: "5%",
        }}
      >
        <HeaderMenu />
        <Container style={{ flex: "1", marginTop: "3%" }}>
          <Row className="justify-content-center">
            <Col md={8} lg={6}>
              <div
                className="login-signup-div"
                style={{
                  backgroundColor: "white",
                  padding: "30px",
                  borderRadius: "10px",
                  paddingLeft: "15%",
                  paddingRight: "15%",
                }}
              >
                {alertVisibility && (
                  <Alert
                    variant="danger"
                    onClose={() => setAlertVisibility(false)}
                    dismissible
                  >
                    {errorMsg}
                  </Alert>
                )}
                <Row>
                  <h2 className="text-center">Enter your email address</h2>
                  <p className="text-center">
                    Please enter the email address associated with your account.
                    We will send you a link to reset your password.
                  </p>
                </Row>
                <Form onSubmit={handleSubmit}>
                  <Form.Group
                    controlId="formBasicPassword"
                    style={{ marginBottom: "25px" }}
                  >
                    <Form.Control
                      className="text-center login-signup-input"
                      type="email"
                      placeholder="Your Email Address"
                      value={email}
                      name="email"
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    className="login-signup-button"
                    disabled={loading}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {loading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          style={{ marginRight: "5px" }}
                        />
                        &nbsp; Loading...
                      </>
                    ) : (
                      "Reset"
                    )}
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container
        fluid
        className="justify-content-center"
        style={{
          marginTop: "1%",
          padding: "1%",
          backgroundColor: "white",
          width: "100%",
          bottom: "0",
        }}
      >
        <Footer />
      </Container>
    </Container>
  );
};

export default ForgetPasswordEmail;
