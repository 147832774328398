import React, { useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { GetAllCategory, CreateCategories, DeleteCategories, UpdateCategories } from '../../api/Categories.Api';
import { CategorysData, CategorysDataMandatory } from "../../models/Categories";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DataTableFilter from "../../components/DataTableFilter";
import { RootState } from "../../../redux/Store";
import {  useSelector } from "react-redux";
import Modal from 'react-bootstrap/Modal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
    TextField,
    Button,
    Grid,
    IconButton,
    Box, Alert, AlertTitle, Fade

} from "@mui/material";

const CategoriesList: React.FC = () => {
    const [isRequired, setIsRequried] = useState<CategorysDataMandatory>({
        name: false,
        description: false,
        order: false,
    });
    const loggedInUser = useSelector((state: RootState) => state.user);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [categoriesList, setCategoriesList] = useState<CategorysData[]>([{
        id: 0,
        name: "",
        description: "",
        order: 0
    }]);

    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
        false
    );
    const [pending, setPending] = React.useState(true);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [formData, setFormData] = useState<CategorysData>({
        id: 0,
        name: "",
        description: "",
        order: 0
    });


    const [categoriesID, setCategoriesID] = useState<number>(0);
    const [alertVisibility, setAlertVisibility] = useState(false);
    useEffect(() => {
        handlecategoriesList();
    }, []);


    const handleAlertClickOpen = () => {
        setOpenAlert(true);
    };

    const handleAlertClickClose = () => {
        setOpenAlert(false);
    };


    const handlecategoriesList = async () => {
        const categoriesList = await GetAllCategory(loggedInUser.token);
        if (categoriesList.status == "200") {
            let response: CategorysData[] = categoriesList.data;
            setCategoriesList(response);
            setPending(false);
        }
    }
    const handleEdit = (item: CategorysData) => {
        setCategoriesID(item.id);
        setFormData(item);
        setShow(true)
    };

    const handleChange = (
        e: React.ChangeEvent<{ name?: string; value: unknown }>
    ) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name as string]: value as string, // Ensure value is treated as a string
        }));
    };

    const removeFeaturelistById = (featList: CategorysData[], id: number) =>
        setCategoriesList(featList.filter(featList => featList.id !== id));

    const handleDelete = async (item: any) => {
        let deletefeatures = await DeleteCategories(loggedInUser.token, item.id);
        if (deletefeatures.status == "200") {
            removeFeaturelistById(categoriesList, item.id)
        }
        else {
            handleAlertClickOpen();
        }
    }
    const handleAddCategories = () => {
        var setTextbox = new CategorysData();
        setTextbox.name = "";
        setTextbox.description = "";
        setTextbox.order = 0;
        setFormData(setTextbox)
        setCategoriesID(0);
        setShow(true)
    }

    const handleCreate = async () => {
        var required = new CategorysDataMandatory();
        var isvalidation = true;
        if (formData.name === "") {
            required.name = true;
            isvalidation = false;
        }
        if (formData.description === "") {
            required.description = true;
            isvalidation = false;
        }
        if (formData.order == null) {
            required.order = true;
            isvalidation = false;
        }
        if (!isvalidation) {
            setIsRequried(required);
            return false;

        }

        let features = await CreateCategories(loggedInUser.token, formData);
        if (features.status == "200") {
            setAlertVisibility(true);
            handlecategoriesList();
            var setTextbox = new CategorysData();
            setTextbox.name = "";
            setTextbox.description = "";
            setTextbox.order = 0;
            setFormData(setTextbox)
            setShow(false)
        }
    }

    const handleUpdate = async () => {
        var required = new CategorysDataMandatory();
        var isvalidation = true;
        if (formData.name === "") {
            required.name = true;
            isvalidation = false;
        }
        if (formData.description === "") {
            required.description = true;
            isvalidation = false;
        }
        if (formData.order === null) {
            required.order = true;
            isvalidation = false;
        }
        if (!isvalidation) {
            setIsRequried(required);
            return false;

        }

        let features = await UpdateCategories(loggedInUser.token, formData);
        if (features.status == "200") {
            setAlertVisibility(true);
            handlecategoriesList();
            setShow(false)
            var setTextbox = new CategorysData();
            setTextbox.name = "";
            setTextbox.description = "";
            setTextbox.order = 0;
            setFormData(setTextbox)
            setShow(false)

        }
    }

    const columns: TableColumn<CategorysData>[] = [
        {
            name: "Id",
            selector: (row) => row.id,
            sortable: true,
            width: "80px" 
        },
        {
            name: "Name",
            selector: (row) => row.name,
            sortable: true
        },
        {
            name: "Description",
            selector: (row) => row.description,
            sortable: true,
        },
        {
            name: "Order",
            selector: (row) => row.order,
            sortable: true,
        },

        {
            name: "Actions",
            cell: (row) => (
                <div className="action-buttons">
                    <IconButton color="primary" onClick={() => handleEdit(row)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton color="error" onClick={() => handleDelete(row)}>
                        <DeleteIcon />
                    </IconButton>
                </div>
            ),
            ignoreRowClick: true,
        },
    ];

    const filteredItems = categoriesList.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };
        return (
            <div>
                <DataTableFilter onFilter={(e: any) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} handleCreateNew={handleAddCategories} addButtonName="Add Category" />
            </div>
        );
    }, [filterText, resetPaginationToggle]);
    return (
        <div style={{ padding: "0", width: "100%" }}>
            <Dialog
                open={openAlert}
                onClose={handleAlertClickClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    Error
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        The Category cannot be deleted It is depend on the product. please delete the product first before deleting Category.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAlertClickClose}>Close</Button>
                </DialogActions>
            </Dialog>
            <Modal show={show} onHide={handleClose} size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Categories</Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <Box>
                        <Grid container spacing={3}>
                            <Grid item sm={12} md={12}>
                                <TextField
                                    label="Name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    sx={{ width: 500 }}
                                    required
                                    error={formData.name === "" && isRequired.name === true}
                                />
                            </Grid>
                            <Grid item sm={12} md={12}>
                                <TextField
                                    label="Description"
                                    name="description"
                                    value={formData.description}
                                    sx={{ width: 500 }}
                                    onChange={handleChange}
                                    required
                                    error={formData.name === "" && isRequired.description === true}
                                />
                            </Grid>
                            <Grid item sm={12} md={12}>
                                <TextField
                                    label="Order"
                                    name="order"
                                    value={formData.order}
                                    onChange={handleChange}
                                    sx={{ width: 500 }}
                                    required
                                    error={formData.order === null && isRequired.order === true}
                                />
                            </Grid>
                            <Grid item sm={12} md={6}>

                            </Grid>
                        </Grid>
                        <Box sx={{ paddingTop: 2 }}>
                            <Grid container spacing={3} justifyContent="center">
                                <Grid item md={3}>
                                    <Fade
                                        in={alertVisibility} //Write the needed condition here to make it appear
                                        timeout={{ enter: 1000, exit: 1000 }} //Edit these two values to change the duration of transition when the element is getting appeared and disappeard
                                        addEndListener={() => {
                                            setTimeout(() => {
                                                setAlertVisibility(false)
                                            }, 4000);
                                        }}
                                    >
                                        <Alert severity="success" variant="standard" className="alert">
                                            <AlertTitle>Success</AlertTitle>
                                        </Alert>
                                    </Fade>
                                </Grid>
                            </Grid>
                        </Box>

                    </Box>
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={handleClose} sx={{ paddingRight: 3 }}>Close</Button>
                    {(categoriesID === 0) ?
                                        <Button
                                            variant="contained"
                                            onClick={handleCreate}>
                                            Save
                                        </Button>
                                        :
                                        <Button
                                            variant="contained"
                                            onClick={handleUpdate}>
                                            Update
                                        </Button>
                                    }
                </Modal.Footer>
            </Modal>
            <DataTable
                title="Categories List"
                columns={columns}
                data={filteredItems}
                pagination
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                persistTableHead
                progressPending={pending}
            />
        </div>

    );
}

export default CategoriesList;
