import React, { useEffect } from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Home from './pages/legacy/Home';
import HomeNew from './pages/Home';
import AboutUs from './pages/legacy/AboutUs';
import Test from './pages/Test';
import Buyers from './pages/legacy/Buyers';
import Sellers from './pages/legacy/Sellers';
import Login from './pages/Login';
import SignupOld from './pages/legacy/Signup';
import Dashboard from './admin/pages/Dashboard';
import AdminLayout from './admin/layout/AdminLayout';
import Products from './admin/pages/Products/Products';
import ProductsList from './admin/pages/Products/ProductsList';
import FeaturesList from './admin/pages/Features/FeaturesList';
import Marketplace from './pages/Marketplace';
import MarketplaceAsset from './pages/MarketplaceAsset';
import Onboarding from './pages/legacy/Onboarding';
import OnboardingNew from './pages/Onboarding';
import CategoriesList from './admin/pages/Category/CategoriesList';
import Customers from './admin/pages/Customers/CustomersList';
import CustomersForm from './admin/pages/Customers/Customers';
import NewsLetter from './admin/pages/Newsletter/NewsLetterList';
import VerifyEmailAddress from './pages/VerifyEmailAddress';
import PrivacyPolicy from './pages/PrivatePolicy';
import CookiesPolicy from './pages/CookiesPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import BuyersNew from './pages/Buyers';
import SellersNew from './pages/Sellers';
import AboutUsNew from './pages/AboutUs';
import GetStarted from './pages/GetStarted';
import Maintenance from './components/Maintenance/Maintenance';
import Signup from './pages/Signup';
import ForgetPassword from './pages/ForgetPassword';
import ForgetPasswordEmail from './pages/ForgetPasswordEmail';
import ForgetPasswordEmailSuccess from './pages/ForgetPasswordEmailSuccess';
function App() {
  const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE === 'true';
  return (
    <BrowserRouter basename='/'>
      <Routes>
      {isMaintenanceMode ? (
                    <Route path="/" element={<Maintenance />} />
                ) :
       ( 
        <>
       <Route path="/" element={<HomeNew />}>
          <Route index element={<HomeNew />} />
        </Route> 
        <Route path="/aboutus" element={<AboutUsNew />}>
          <Route index element={<AboutUsNew />} />
        </Route>
        <Route path="/buyers" element={<BuyersNew />}>
          <Route index element={<BuyersNew />} />
        </Route>
        <Route path="/sellers" element={<SellersNew />}>
          <Route index element={<SellersNew />} />
        </Route>
        <Route path="/login" element={<Login />}>
          <Route index element={<Login />} />
        </Route>
        <Route path="/signupold" element={<SignupOld />}>
          <Route index element={<SignupOld />} />
        </Route>
        <Route path="/signup" element={<Signup />}>
          <Route index element={<Signup />} />
        </Route>
        <Route path="/ForgetPassword/:id" element={<ForgetPassword />}>
          <Route index element={<ForgetPassword />} />
        </Route>
        <Route path="/ForgetPassword" element={<ForgetPasswordEmail />}>
          <Route index element={<ForgetPasswordEmail />} />
        </Route>
        <Route path="/ForgetPasswordSuccess" element={<ForgetPasswordEmailSuccess />}>
          <Route index element={<ForgetPasswordEmailSuccess />} />
        </Route>
        <Route path="/marketplace" element={<Marketplace />}>
          <Route index element={<Marketplace />} />
        </Route>
        <Route path="/test" element={<Test />}>
          <Route index element={<Test />} />
        </Route>
        <Route path="/marketplace/:id" element={<MarketplaceAsset />}>
          <Route index element={<MarketplaceAsset />} />
        </Route>
        <Route path="/onboarding" element={<OnboardingNew />}>
          <Route index element={<OnboardingNew />} />
        </Route>
        <Route path="/VerifyEmailAddress/:id" element={<VerifyEmailAddress />}>
          <Route index element={<VerifyEmailAddress />} />
        </Route>
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />}>
          <Route index element={<PrivacyPolicy />} />
        </Route>
        <Route path="/CookiesPolicy" element={<CookiesPolicy />}>
          <Route index element={<CookiesPolicy />} />
        </Route>
        <Route path="/TermsAndConditions" element={<TermsAndConditions />}>
          <Route index element={<TermsAndConditions />} />
        </Route>
        <Route path="/Services" element={<GetStarted />}>
          <Route index element={<GetStarted />} />
        </Route>



        {/* admin routing */}
        <Route element={<AdminLayout />}>
          <Route path='admin/dashboard' element={<Dashboard />} />
          <Route path='admin/test' element={<Test />} />
          <Route path='admin/products' element={<Products />} />
          <Route path='admin/productslist' element={<ProductsList />} />
          <Route path='admin/featureslist' element={<FeaturesList />} />
          <Route path='admin/categorieslist' element={<CategoriesList />} />
          <Route path='admin/customersform' element={<CustomersForm />} />
          <Route path='admin/customers' element={<Customers />} />
          <Route path='admin/maillist' element={<NewsLetter />} />
        </Route>
        </>
       )}
      </Routes>
    </BrowserRouter>
  );
}

export default App;