import React, { useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { GetAllCustomers } from '../../api/Customer.Api';
import { CustomersData } from "../../models/Customers";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from 'react-router-dom';
import DataTableFilter from "../../components/DataTableFilter";
import { RootState } from "../../../redux/Store";
import { useSelector } from "react-redux";
import {
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Button,
    Grid,
    SelectChangeEvent,
    IconButton
} from "@mui/material";

import { questionnaire } from '../../../static/Questionnaire';

const CustomersList: React.FC = () => {

    const [pending, setPending] = React.useState(true);
    const navigate = useNavigate();
    const loggedInUser = useSelector((state: RootState) => state.user);
    const [customerList, setCustomerList] = useState<CustomersData[]>([{
        id: 0,
        name: "",
        address: "",
        city: "",
        postcode: "",
        country: "",
        email: "",
        phone: "",
        status: "",
        created_date: "",
        roles: "",
        package: "",
        companyName: "",
        lastLogin: "",
        companyRegister: "",
        position: "",
        rolesId: "",
        documentAccess:false
    }]);

    const [filterText, setFilterText] = React.useState("");
    const [questions, setQuestions] = React.useState("");
    const [answer, setAnswer] = React.useState("");
    const [min, setMin] = React.useState(0);
    const [max, setMax] = React.useState(0);
    const [ismultiple, setIsmultiple] = React.useState(false);
    const [answerList, setAnswerlist] = React.useState<any>([]);
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
        false
    );

    useEffect(() => {
        handleCustomersList(0, "", "", 0, 0);
    }, []);

    const handleCustomersList = async (questionId: number, answer: string, type: string, min: number, max: number) => {
        const customerList = await GetAllCustomers(loggedInUser.token, questionId, answer, type, min, max);
        if (customerList.status == "200") {
            setCustomerList(customerList.data);
            setPending(false);
        }
    }
    const handleEdit = (item: CustomersData) => {
        let url = "/admin/customersform?id=" + item.id;
        navigate(url);
    };

    const handleDropdown = (e: SelectChangeEvent<string>) => {
        const { name, value } = e.target;
        if (name === "questions") {
            console.log(value);
            setQuestions(value);
            const answers = questionnaire?.filter(item => item.id === Number(value));
            setAnswerlist(answers[0]);
            if (answers[0].type === "Dropdown" || (answers[0].type === "Multiple") || (answers[0].type === "TrueDropdown")) {
                setIsmultiple(true);
            }
            else {
                setIsmultiple(false);
            }
        }
        else {
            setAnswer(value);
        }

    };

    const colorCell = (value: string) => {
        if (value === "Buyer") {
          return <div style={{ color: "green" }}>{value}</div>;
        } else if (value === "Seller") {
            return <div style={{ color: "rgb(25, 118, 210)" }}>{value}</div>;
        } else if (value === "PendingApproval") {
            return <div style={{ color: "red" }}>{value}</div>;
        } else if (value === "Approved") {
            return <div style={{ color: "#06bf06" }}>{value}</div>;
        }   else {
            return <div>{value}</div>;
          }    
      };

    const columns: TableColumn<CustomersData>[] = [
        {
            name: "Id",
            selector: (row) => row.id,
            sortable: true,
            width: "80px"
        },
        {
            name: "Name",
            selector: (row) => row.name,
            sortable: true
        },
        {
            name: "Email",
            selector: (row) => row.email,
            width: "220px"
        },
        {
            name: "Phone",
            selector: (row) => row.phone,
            sortable: true,
        },
        {
            name: "Roles",
            selector: (row) => (row.roles),
            cell: (row) => colorCell(row.roles)
        },
        {
            name: "Position",
            selector: (row) => (row.position)
        },
        {
            name: "Status",
            selector: (row) => (row.status),
            sortable: true,
            cell: (row) => colorCell(row.status),
        },
        {
            name: "Actions",
            cell: (row) => (
                <div className="action-buttons">
                    <IconButton color="primary" onClick={() => handleEdit(row)}>
                        <EditIcon />
                    </IconButton>
                </div>
            ),
            ignoreRowClick: true,
        },
    ];
    const handleNewProducts = () => {
    }

    const filteredItems = customerList.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const ClearFilter = async () => {
        handleCustomersList(0, "", "", 0, 0);
        setQuestions('');
        setIsmultiple(false);
    }

    const handleFilter = async () => {
        let type = ismultiple ? "text" : "range";
        if (type === "text") {
            handleCustomersList(Number(questions), answer, type, 0, 0);
        }
        else {
            handleCustomersList(Number(questions), answer, type, min, max);
        }

    }

    const handleChange = (
        e: React.ChangeEvent<{ name?: string; value: unknown }>
    ) => {
        const { name, value } = e.target;
        if (name === "Min") {
            setMin(value as number)
        }
        if (name === "Max") {
            setMax(value as number)
        }
    };

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <DataTableFilter onFilter={(e: any) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} handleCreateNew={handleNewProducts} addButtonName="" isShow="false" />
        );
    }, [filterText, resetPaginationToggle]);
    return (


        <div style={{ padding: "0", width: "100%" }}>
            <h4>Customers List</h4>
            <Grid container spacing={3}>
                <Grid item sm={12} md={4}>
                    <FormControl fullWidth>
                        <InputLabel id="question-select">Questions</InputLabel>
                        <Select
                            name="questions"
                            id="questions"
                            value={questions}
                            onChange={handleDropdown}
                            labelId="question-select"
                            label="Questions">
                            {(questionnaire?.filter(item => item.filter === true)).map((quest) => (
                                <MenuItem key={quest.id} value={quest.id}>
                                    {quest.questionnaire.question}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                {questions !== "" ?
                    ismultiple ?
                        <Grid item sm={12} md={4}>
                            <FormControl fullWidth>
                                <InputLabel id="answer-select">Answers</InputLabel>
                                <Select
                                    name="answers"
                                    id="answers"
                                    value={answer}
                                    onChange={handleDropdown}
                                    labelId="answer-select"
                                    label="Answers"
                                >
                                    {answerList.questionnaire.answers?.map((answ: any) => (
                                        <MenuItem key={answ.id} value={answ.answer}>
                                            {answ.answer}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid> : <Grid item sm={12} md={5}>
                            <TextField
                                label="Min"
                                name="Min"
                                value={min}
                                onChange={handleChange}
                                type="number"
                            />
                            <TextField
                                label="Max"
                                name="Max"
                                value={max}
                                style={{ marginLeft: "10px" }}
                                onChange={handleChange}
                                type="number"
                            />
                        </Grid> : ""}
                {questions !== "" ?
                    <Grid item sm={12} md={3}>
                        <Button
                            variant="contained"
                            onClick={handleFilter}
                            style={{ marginLeft: "10px", height: "56px"}}
                        >
                            Filter
                        </Button>
                        <Button
                            variant="contained"
                            onClick={ClearFilter}
                            style={{ marginLeft: "10px", height: "56px"}}
                        >
                            Clear Filter
                        </Button>
                    </Grid> : ""}
            </Grid >
            <DataTable
                columns={columns}
                data={filteredItems}
                //style={{backgroundColor : "transparent", marginTop : "-50px"}}
                pagination
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                persistTableHead
                progressPending={pending}
            />
        </div >

    );
}


export default CustomersList;