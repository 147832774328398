import { GetAllCategory } from "../../apis/EpendisisApi";
import {
  Answer,
  AnsweredQuestionnaire,
} from "../../interfaces/pages/Questionnaire";

export class QuestionnaireUtility {
    static async answerGenerator(
      questionnaireInput: AnsweredQuestionnaire[],
      token?:string
    ): Promise<AnsweredQuestionnaire[]> {
      var generatedQuestionnaire = questionnaireInput;
  
      for (const item of generatedQuestionnaire) {
        if (item.answersSource === "Categories") {
          const categoryData = await GetAllCategory(token?.toString() ?? "");
          if (categoryData.length > 0) {
            const answerArray: Answer[] = categoryData.map((category, index) => ({
              id: index + 1,
              answer: category.name,
              order: index + 1,
            }));
            item.questionnaire.answers = answerArray;
          }
        }
      }
  
      return generatedQuestionnaire;
    }
  }
