import settings from '../../settings';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { CategorysData } from '../models/Categories';

const EpendisisApi: AxiosInstance = axios.create({
  baseURL: settings.Functions.EpendisisApi,
});


export const GetAllCategory = async (token: string): Promise<any> => {
  try {
    EpendisisApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const response: AxiosResponse<CategorysData[]> = await EpendisisApi.get('/api/categories/getAllCategories');
    return response;
  }catch (error: any) {
    return error?.response;
  }
}

export const CreateCategories = async (token: string,data:CategorysData): Promise<any> => {
  try {
    EpendisisApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const response: AxiosResponse<any> = await EpendisisApi.post('/api/categories/createCategories',data);
    return response;
  } catch (error: any) {
    return error?.response;
  }
}

export const UpdateCategories = async (token: string,data:CategorysData): Promise<any> => {
  try {
    EpendisisApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const response: AxiosResponse<any> = await EpendisisApi.put('/api/categories/updateCategories',data);
    return response;
  } catch (error: any) {
    return error?.response;
  }
}

export const DeleteCategories = async (token: string,id:number): Promise<any> => {
  try {
    EpendisisApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const response: AxiosResponse<any> = await EpendisisApi.delete('/api/categories/deleteCategories/'+id);
    return response;
  } catch (error: any) {
    return error?.response;
  }
}