import { Row, Navbar, Nav, Col, NavDropdown } from "react-bootstrap";
import "./HeaderMenu.css";
import logo from "../../assets/ependisis-logo-no-outline.png";
import { useEffect, useState } from "react";
import { UserInfo } from "../../redux/UserInfoReducer";
import { getUserInfo, useLogout } from "../../auth/AuthUtils";
import { Link } from "react-router-dom";
import { PersonCircle } from "react-bootstrap-icons";

function HeaderMenu() {
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const handleLogout = useLogout();

  useEffect(() => {
    const fetchUserInfo = async () => {
      const user = getUserInfo();
      if (user) {
        setUserInfo(user);
      }
    };
    fetchUserInfo();
  }, []);

  const handleAdminPage = () => {
    console.log("Accessing Admin Page");
  };

  return (
    <div style={{ width: "100%", height: "50%", display: "flex" }}>
      <Col></Col>
      <Col xs={8}>
        <Row
          className="justify-content-center"
          style={{ zIndex: "2", position: "relative", height: "auto" }}
        >
          <div className="logo" style={{ height: "auto" }}>
            <a href="/">
              <img
                src={logo}
                alt="Logo"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "flex",
                  maxWidth: "100%", // Limiting the maximum width
                  maxHeight: "100%", // Limiting the maximum height
                }}
              />
            </a>
          </div>
        </Row>
        <Row
          className="justify-content-center"
          style={{ paddingBottom: "20px" }}
        >
          <Navbar
            bg="transparent"
            expand="lg"
            style={{ paddingTop: "0", paddingBottom: "0" }}
          >
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mx-auto">
                <Nav.Link
                  href="/buyers"
                  className="header-option"
                  style={{ margin: "0 50px" }}
                >
                  BUYERS
                </Nav.Link>
                <Nav.Link
                  href="/aboutUs"
                  className="header-option"
                  style={{ margin: "0 50px" }}
                >
                  ABOUT
                </Nav.Link>
                <Nav.Link
                  href="/sellers"
                  className="header-option"
                  style={{ margin: "0 50px" }}
                >
                  SELLERS
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Row>
      </Col>
      <Col className="last-column">
        <Nav className="ai-center">
          <NavDropdown
            title={<PersonCircle size={30} style={{ cursor: "pointer" }} />}
            id="basic-nav-dropdown"
            onSelect={(eventKey: any) => {
              if (!userInfo && eventKey === "login") {
                window.location.href = "/login";
              }
            }}
          >
            {userInfo === null && (
              <NavDropdown.Item eventKey="login">Log in</NavDropdown.Item>
            )}
            {userInfo !== null && (
              <>
                <NavDropdown.Item disabled>
                  Hello, {userInfo.name}
                </NavDropdown.Item>
                <NavDropdown.Divider />
                {userInfo?.roles === "Admin" && (
                  <NavDropdown.Item
                    as={Link}
                    to="/admin/dashboard/"
                    onClick={handleAdminPage}
                  >Admin Page</NavDropdown.Item>
                )}
                <NavDropdown.Item onClick={handleLogout}>
                  Logout
                </NavDropdown.Item>
              </>
            )}
          </NavDropdown>
        </Nav>
      </Col>
    </div>
  );
}

export default HeaderMenu;
