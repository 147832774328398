import React, { useEffect, useRef, useState } from "react";
import "./ScrollingSections.css"; // Import your CSS file for styling

interface Section {
  title: string;
  text: string;
}

interface ScrollingSectionsProps {
  sections: Section[];
}

const ScrollingSections: React.FC<ScrollingSectionsProps> = ({ sections }) => {
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const containerRef = useRef<HTMLDivElement>(null);
  const lastScrollPosition = useRef<number>(0);

  useEffect(() => {
    const handleScroll = () => {
      if (!containerRef.current) return;
      const container = containerRef.current;

      // Check if scrolling down or if already at the bottom of the page
      const scrollPosition = window.scrollY + container.clientHeight / 3;
      if (
        window.innerHeight + Math.round(window.scrollY) >=
        document.body.offsetHeight
      ) {
        setActiveIndex(sections.length - 1);
      } else if (
        scrollPosition > lastScrollPosition.current ||
        window.innerHeight + window.scrollY >= document.body.offsetHeight
      ) {
        const container = containerRef.current;
        const scrollPosition = window.scrollY + window.innerHeight * 0.7;

        let newIndex = activeIndex;
        for (let i = 0; i < sections.length; i++) {
          const sectionElement = container.children[i] as HTMLElement;
          if (sectionElement.offsetTop <= scrollPosition) {
            newIndex = i;
          } else {
            break;
          }
        }
        if (newIndex > activeIndex) {
          setActiveIndex(newIndex);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [activeIndex, sections]);

  return (
    <div className="scrolling-sections-container" ref={containerRef}>
      {sections.map((section, index) => (
        <div
          key={index}
          className={`section ${index <= activeIndex ? "active" : ""}`}
        >
          <h2
            className={`section-heading ${
              index <= activeIndex ? "section-heading-active" : ""
            }`}
          >
            {section.title}
          </h2>
          <div className={`section-paragraph ${
                  index <= activeIndex ? "section-paragraph-active" : ""
                }`}>
            {index <= activeIndex && (
              <p>
                {section.text}
              </p>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ScrollingSections;
