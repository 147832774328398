import { Col, Container, ListGroup, Row } from "react-bootstrap";
import HeaderMenu from "../components/HeaderMenu/HeaderMenu";
import GetStartedHighlight from "../components/GetStartedHighlight/GetStartedHighlight";
import GetStartedHighlightProps from "../interfaces/components/GetStartedHighlight/GetStartedHighlightProps";
import GetStartedImage from "../assets/getstarted.png";
import { BiInfoCircle } from "react-icons/bi";
import { useEffect, useRef, useState } from "react";
import ContactUs from "../components/ContactUs/ContactUs";
import Footer from "../components/Footer/Footer";

const propsArray: GetStartedHighlightProps[] = [
  {
    enumeration: "01",
    heading: "Sell Side services",
    paragraph:
      "If you’re navigating the M&A world, our detailed guidance and support under Sell Side services ensure you’re well-prepared. We guide you through every step, ensuring confidence that you’ll maximize the value of your firm. We assist our clients to understand all the sale options comprehensively, following a strategic approach that explains the intricacies of selling a business.",
  },
  {
    enumeration: "02",
    heading: "Raising capital",
    paragraph:
      "With a robust track record in sourcing capital from venture capital, private equity firms, family offices, and institutional investors, our capital raising services are designed to meet your business’s investor needs. Whether you are raising growth capital, management buyouts or launching a new Investment Fund, we provide tailored solutions to address your capital requirements effectively.",
  },
  {
    enumeration: "03",
    heading: "Buy Side services",
    paragraph:
      "Specializing in middle-market M&A, our Buy Side services tap into extensive databases of small- and middle-sized businesses globally, tailored to your criteria. Each deal involves a team of corporate finance experts, blending financial, sectoral, and international expertise.",
  },
  {
    enumeration: "04",
    heading: "Strategic Advisory & Company Valuation",
    paragraph:
      "Our specialists at Ependisis.com assist in creating and implementing strategic plans to propel your business forward. In a dynamic business environment, our Strategic Advisory & Company Valuation services help you navigate changes effectively, bringing the future into focus and elevating your business to the next level.",
  },
];

const bulletListItems = [
  "More Information request received",
  "NDAs to be exchanged along with Ependisis T&C",
  "Data room release and negotiations coordination",
  "Letter of Intend received",
  "Definitive Share Purchase Agreement",
];

const bulletIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    fill="currentColor"
    className="bi bi-info-circle"
    viewBox="0 0 16 16"
    style={{ marginRight: "20px" }}
  >
    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
  </svg>
);

const GetStarted: React.FC = () => {
  const [bulletListHeight, setBulletListHeight] = useState(0);
  const bulletListRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    // Calculate the height of the bullet list once it's rendered
    if (bulletListRef.current) {
      setBulletListHeight(bulletListRef.current.clientHeight);
    }
  }, []);

  return (
    <Container
      fluid
      style={{
        position: "relative",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        paddingLeft: 0,
        paddingRight: 0,
      }}
    >
      <Row
        fluid
        className="justify-content-center w-100"
        style={{ backgroundColor: "#101324", width: "100%", margin: 0 }}
      >
        <HeaderMenu />
      </Row>
      <Row
        fluid
        className="justify-content-center"
        style={{ marginLeft: "4%", marginRight: "4%", marginTop: "2%" }}
      >
        <Row>
          <h2 className="home-caption">Redefining Private</h2>
          <h2 className="home-caption">Equity Marketplaces</h2>
        </Row>
        <Row style={{ marginTop: "1%", paddingLeft: "1%" }}>
          <p
            style={{ fontStyle: "italic", margin: "0", padding: "0" }}
            className="home-subcaption"
          >
            {" "}
            At Ependisis.com our services go beyond being just another online marketplace. Here’s what sets us apart:
          </p>
        </Row>
        <Row style={{ marginTop: "2%" }}>
          <hr
            style={{
              borderTop: "3px solid black",
              opacity: "1",
              marginBottom: "0",
            }}
          />
        </Row>
      </Row>
      <Row
        fluid
        className="justify-content-center"
        style={{ marginLeft: "4%", marginRight: "4%", marginTop: "1%" }}
      >
        {propsArray.map((props, index) => (
          <Col>
            <GetStartedHighlight
              key={index}
              enumeration={props.enumeration}
              heading={props.heading}
              paragraph={props.paragraph}
            />
          </Col>
        ))}
      </Row>
      <Row
        fluid
        className="justify-content-center"
        style={{ marginLeft: "4%", marginRight: "4%", marginTop: "5%" }}
      >
        <Col style={{ display: "flex" }}>
          <img
            src={GetStartedImage}
            alt="Get Started"
            style={{
              marginLeft: "auto",
              maxWidth: "100%",
              height: bulletListHeight, // Set the height dynamically
            }}
          />
        </Col>
        <Col>
          <ListGroup
            ref={bulletListRef as unknown as React.RefObject<HTMLDivElement>}
          >
            {bulletListItems.map((item, index) => (
              <ListGroup.Item
                key={index}
                style={{
                  border: "0",
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "20px",
                  //paddingRight: "20%",
                }}
              >
                {bulletIcon}
                <span
                  style={{
                    fontFamily: '"Helvetica-Neue-Light", sans-serif',
                    color: "#272f53",
                    fontSize: "25px",
                  }}
                >
                  {item}
                </span>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
      </Row>
      <Row className="justify-content-center" style={{ marginTop: "5%" }}>
        <ContactUs />
      </Row>
      <Row
        className="justify-content-center"
        style={{ marginLeft: "1%", marginRight: "1%", marginTop: "1%" }}
      >
        <Footer />
      </Row>
    </Container>
  );
};

export default GetStarted;
