import settings from '../../settings';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { FeaturesData } from '../models/features';

const EpendisisApi: AxiosInstance = axios.create({
  baseURL: settings.Functions.EpendisisApi,
});


export const GetAllFeatures = async (token: string): Promise<any> => {
  try {
    EpendisisApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const response: AxiosResponse<any> = await EpendisisApi.get('/api/features/getAllFeatures');
    return response;
  } catch (error: any) {
    return error?.response;
  }
}

export const CreateFeatures = async (token: string,data:FeaturesData): Promise<any> => {
  try {
    EpendisisApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const response: AxiosResponse<any> = await EpendisisApi.post('/api/features/createFeatures',data);
    return response;
  } catch (error: any) {
    return error?.response;
  }
}

export const UpdateFeatures = async (token: string,data:FeaturesData): Promise<any> => {
  try {
    EpendisisApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const response: AxiosResponse<any> = await EpendisisApi.put('/api/features/updateFeatures',data);
    return response;
  } catch (error: any) {
    return error?.response;
  }
}

export const DeleteFeatures = async (token: string,id:number): Promise<any> => {
  try {
    EpendisisApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const response: AxiosResponse<any> = await EpendisisApi.delete('/api/features/deleteFeatures/'+id);
    return response;
  } catch (error: any) {
    return error?.response;
  }
}