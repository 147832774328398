import React from "react";
import { Badge } from "react-bootstrap";
import { CircleFill, Fonts } from "react-bootstrap-icons";
import { Icon } from '../Icon/Icon'; // Assuming this is your custom Icon component
import { IconType } from "react-icons";

interface CircleIconProps {
  icon: React.ElementType; // This can be a React Bootstrap icon component
  color?: string;
  size?: string;
}

const CircleIcon: React.FC<CircleIconProps> = ({
  icon: IconComponent,
  color = "white",
  size = "2x",
}) => {
  const iconSize = "80px"; // Set the size of the icon
  const backgroundSize = "150px"; // Set the size of the circular background
  type Position = "relative" | "absolute";

  const iconStyle = {
    background: "#161C3B", // Replace 'your-background-color' with the desired background color
    borderRadius: "50%", // Creates a circular background
    width: backgroundSize,
    height: backgroundSize,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative" as Position,
    top: "25px", // Adjust the margin from the top as needed
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 1,
  };

  const iconImageStyle = {
    width: iconSize,
    height: iconSize,
    color: "white",
    zIndex: 1,
    position: "relative" as Position,
  };

  return (
    <div style={iconStyle}>
      <IconComponent style={iconImageStyle} />
    </div>
  );
};

export default CircleIcon;
