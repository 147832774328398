import settings from "../settings";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { RegisterRequest } from "../interfaces/models/RegisterRequest";
import { RegisterResponse } from "../interfaces/models/RegisterResponse";
import { LoginRequest } from "../interfaces/models/LoginRequest";
import { LoginResponse } from "../interfaces/models/LoginResponse";
import { ListingItemProduct } from "../interfaces/models/ListingItemProduct";
import { ProductDetails } from "../interfaces/models/ProductDetails";
import secureLocalStorage from "react-secure-storage";
import { CategorysData } from "../admin/models/Categories";
import { UpdateCustomerRequest } from "../interfaces/models/UpdateCustomerRequest";
import { SaveCustomerQuestionRequest } from "../interfaces/models/SaveCustomerQuestionRequest";
import { AffectedRowsResponse } from "../interfaces/models/AffectedRowsResponse";
import { Customer } from "../interfaces/models/Customer";
import { FavouriteProductRequest } from "../interfaces/models/FavouriteProductRequest";
import { SendContactFormRequest } from "../interfaces/models/SendContactFormRequest";
import { SubscribeNewsletterRequest } from "../interfaces/models/SubscribeNewsletterRequest";
import { ForgetPasswordRequest } from "../interfaces/models/ForgetPasswordRequest";

const EpendisisApi: AxiosInstance = axios.create({
  baseURL: settings.Functions.EpendisisApi,
});

EpendisisApi.defaults.headers.common[
  "Authorization"
] = `Bearer ${secureLocalStorage.getItem("token")}`;

export const Register = async (data: RegisterRequest): Promise<any> => {
  try {
    const response: AxiosResponse<RegisterResponse> = await EpendisisApi.post(
      "/api/register",
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const GenerateVerification = async (
  email: string,
  token: string
): Promise<any> => {
  try {
    EpendisisApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response: AxiosResponse<any> = await EpendisisApi.get(
      `/api/generateVerification/${email}/register`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const VerifyEmail = async (code: string): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await EpendisisApi.get(
      `/api/verifyEmailAddress/${code}`
    );
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const ForgetGenerateVerification = async (
  email: string
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await EpendisisApi.get(
      `/api/generateVerification/${email}/forget`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};


export const ForgetPasswordCall = async (data: ForgetPasswordRequest): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await EpendisisApi.post(
      `/api/ChangePassword`,
      data
    );
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const Login = async (data: LoginRequest): Promise<any> => {
  try {
    const response: AxiosResponse<LoginResponse> = await EpendisisApi.post(
      "/api/login",
      data
    );
    return response;
  } catch (error: any) {
    return error?.response;
  }
};

export const GetProductsMarketPlace = async (
  userId: string,
  token: string
): Promise<ListingItemProduct[]> => {
  try {
    if (token !== "") {
      EpendisisApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
    const response: AxiosResponse<ListingItemProduct[]> =
      await EpendisisApi.get(`/api/products/getProductsMarketPlace/${userId}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const GetProductsDetailsById = async (
  productId: string,
  userId: string
): Promise<ProductDetails> => {
  try {
    const response: AxiosResponse<ProductDetails> = await EpendisisApi.get(
      `/api/products/getProductsDetailsById/${userId}/${productId}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const GetAllCategory = async (
  token?: string
): Promise<CategorysData[]> => {
  try {
    if (!(token === null || token === undefined || token === "")) {
      EpendisisApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
    const response: AxiosResponse<CategorysData[]> = await EpendisisApi.get(
      "/api/categories/getAllCategories"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const CreateFavouriteProduct = async (
  data: FavouriteProductRequest
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await EpendisisApi.post(
      "/api/createUserFavourites",
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const DeleteFavouriteProduct = async (
  data: FavouriteProductRequest
): Promise<any> => {
  try {
    const axiosConfig: AxiosRequestConfig = {
      data: data,
    };
    const response: AxiosResponse<any> = await EpendisisApi.delete(
      "/api/deleteUserFavourites",
      axiosConfig
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const UpdateCustomer = async (
  data: UpdateCustomerRequest
): Promise<AffectedRowsResponse> => {
  try {
    const response: AxiosResponse<AffectedRowsResponse> =
      await EpendisisApi.put("/api/updateCustomer", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const SaveCustomerQuestions = async (
  data: SaveCustomerQuestionRequest[],
  id: string
): Promise<AffectedRowsResponse> => {
  try {
    const response: AxiosResponse<AffectedRowsResponse> =
      await EpendisisApi.post(`/api/saveCustomerQuestion/${id}`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const UploadCustomerDocuments = async (
  data: FormData,
  id: string
): Promise<AffectedRowsResponse> => {
  try {
    const response: AxiosResponse<AffectedRowsResponse> =
      await EpendisisApi.post(`/api/uploadCustomerDoc/${id}`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetCustomerById = async (
  id: string,
  token: string
): Promise<Customer> => {
  try {
    EpendisisApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response: AxiosResponse<Customer> = await EpendisisApi.get(
      `/api/getCustomerById/${id}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const SendContactForm = async (
  data: SendContactFormRequest
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await EpendisisApi.post(
      `/api/SendContactForm/`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const SubscribeNewsletter = async (
  data: SubscribeNewsletterRequest
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await EpendisisApi.post(
      `/api/SubscribeNewsletter/`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const RequestDocumentAccess = async (
  productId: string,
  userId: string
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await EpendisisApi.get(
      `/api/RequestDocumentAccess/${userId}/${productId}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const ContactAdminForProduct = async (
  productId: string,
  userId: string
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await EpendisisApi.get(
      `/api/contactAdminForProduct/${userId}/${productId}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
