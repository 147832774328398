import { createSlice, PayloadAction } from "@reduxjs/toolkit"

    export class UserInfo {
        token: string;
        id: number;
        name:string;
        address:string;
        email:string;
        phone:string;
        roles:string;
        status:UserStatus;

        constructor(
            token: string,
            id: number,
            name: string,
            address: string,
            email: string,
            phone: string,
            roles: string,
            status: UserStatus
          ) {
            this.token = token;
            this.id = id;
            this.name = name;
            this.address = address;
            this.email = email;
            this.phone = phone;
            this.roles = roles;
            this.status = status;
          }
    }
    

export interface UserInitialState {
    token:string,
    userInfo:string
}

export enum UserStatus {
    PendingVerification = 'PendingVerification',
    PendingDetails = 'PendingDetails',
    PendingDocuments = 'PendingDocuments',
    PendingApproval = 'PendingApproval',
    Approved = 'Approved',
    Blocked = 'Blocked',
  }

const initialState: UserInitialState = {
    token:"",
    userInfo: ""
}

export const userSlice = createSlice({
    name: 'User',
    initialState,
    reducers: {
        addUserInfo: (state, action: PayloadAction<string>) => {
            state.userInfo = action.payload;
        },
        addToken:(state,action: PayloadAction<string>)=>{
            state.token = action.payload
        }
    }
})

export const { addUserInfo,addToken } = userSlice.actions
export default userSlice.reducer