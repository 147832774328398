import React, { useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { GetNewsLetter} from '../../api/Customer.Api';
import { NewsLetter } from "../../models/NewsLetter";
import DataTableFilter from "../../components/DataTableFilter";
import { RootState } from "../../../redux/Store";
import { useDispatch, useSelector } from "react-redux";

const NewsLetterList: React.FC = () => {
    const [pending, setPending] = React.useState(true);
    const loggedInUser = useSelector((state: RootState) => state.user);
    const [letterList, setLetterList] = useState<NewsLetter[]>([{
        id: 0,
        email: "",
        active: false,
        timestamp: ""
    }]);

    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
        false
    );

    useEffect(() => {
        handlecategoriesList();
    }, []);


    const handlecategoriesList = async () => {
        const letterList = await GetNewsLetter(loggedInUser.token);
        if (letterList.status == "200") {
            let response: NewsLetter[] = letterList.data;
            setLetterList(response);
            setPending(false);
        }
    }

    const columns: TableColumn<NewsLetter>[] = [
        {
            name: "Id",
            selector: (row) => row.id,
            sortable: true,
            width: "80px" 
        },
        {
            name: "Email",
            selector: (row) => row.email,
            sortable: true
        },
        {
            name: "Active",
            selector: (row) => (row.active === true?"Yes":"No")
        },
        {
            name: "Last Modified",
            selector: (row) => row.timestamp,
            sortable: true,
        }
    ];

    const filteredItems = letterList.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };
        return (
            <div>
                <DataTableFilter onFilter={(e: any) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} handleCreateNew="" addButtonName="" isShow="false"/>
            </div>
        );
    }, [filterText, resetPaginationToggle]);
    return (
        <div style={{ padding: "0", width: "100%" }}>
            <DataTable
                title="Mail List"
                columns={columns}
                data={filteredItems}
                pagination
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                persistTableHead
                progressPending={pending}
            />
        </div>

    );
}

export default NewsLetterList;
