import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/Store";
import { Navigate  } from 'react-router-dom';
import { addUserInfo, addToken } from "../../redux/UserInfoReducer";
import { isExpired } from "react-jwt";
import secureLocalStorage from "react-secure-storage";

const AuthorizeWrapper = ({ children }: { children: JSX.Element }) => {
  const loggedInUser = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  let token: any = loggedInUser.token;
  let userDetails: any = loggedInUser.userInfo;

  if (!token || !userDetails) {
    token = secureLocalStorage.getItem('token');
    userDetails = secureLocalStorage.getItem('user');
    if (!token || !userDetails) {
      return <Navigate to="/login" />;
    }
    dispatch(addToken(token.toString()));
    dispatch(addUserInfo(userDetails.toString()));
  }

  const isMyTokenExpired = isExpired(token);
  if (isMyTokenExpired) {
    secureLocalStorage.clear();
    return <Navigate to="/login" />;
  }

  return children;
};

export default AuthorizeWrapper;