import React from "react";
import { Stepper, Step, StepLabel } from "@mui/material";
import OnboardingStepperProps from "../../interfaces/components/OnboardingStepper/OnboardingStepperProps";
import { Row } from "react-bootstrap";

const OnboardingStepper: React.FC<OnboardingStepperProps> = ({
  steps,
  currentStep,
}) => {
  return (
    <Row style={{ paddingLeft: "50px", paddingRight: "50px" }}>
      <Row style={{ marginTop: "12px", marginBottom: "12px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {steps.map((step, index) => (
            <div
              key={index}
              style={{
                textAlign:
                  index === 0
                    ? "left"
                    : index === steps.length - 1
                    ? "right"
                    : "center",
              }}
            >
              <span
                style={{
                  color: "#526ABF",
                  fontSize: "38px",
                  fontFamily: '"Helvetica-Neue", sans-serif',
                  fontWeight: index === currentStep ? "bold" : "normal",
                }}
              >
                {index < currentStep ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="38px"
                    height="38px"
                    fill="currentColor"
                    className="bi bi-check-circle-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                  </svg>
                ) : (
                  (index + 1).toString().padStart(2, "0") // Converts index to '01', '02', '03', etc.
                )}
              </span>
            </div>
          ))}
        </div>
      </Row>
      <Row>
        <hr
          style={{
            borderTop: "2px solid black",
            opacity: "0.8",
            marginBottom: "0",
          }}
        />
      </Row>
      <Row
        style={{ marginTop: "12px", marginBottom: "12px", textAlign: "center" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {steps.map((step, index) => (
            <div
              key={index}
              style={{
                textAlign:
                  index === 0
                    ? "left"
                    : index === steps.length - 1
                    ? "right"
                    : "center",
              }}
            >
              {step.includes(" ") ? (
                step.split(" ").map((part, partIndex) => (
                  <div key={partIndex}>
                    <span
                      style={{
                        color: "#272f53",
                        fontSize: "30px",
                        fontWeight: index === currentStep ? "bold" : "normal",
                      }}
                    >
                      {part}
                    </span>
                  </div>
                ))
              ) : (
                <span
                  style={{
                    color: "#272f53",
                    fontSize: "30px",
                    fontWeight: index === currentStep ? "bold" : "normal",
                  }}
                >
                  {step}
                </span>
              )}
            </div>
          ))}
        </div>
      </Row>
    </Row>
  );
};

export default OnboardingStepper;
