import { Col, Row } from "react-bootstrap";
import "./Footer.css";
import logo from "../../assets/logo-black-letters.png";

const Footer: React.FC = () => {
  return (
    <Row className="footer-row" fluid>
      <Col>
        <Row
          className="link-footer"
          style={{ fontSize: "27px", color: "#2F3B68" }}
        >
          Useful Links
        </Row>
        <Row
          className="link-footer"
          style={{ fontSize: "18px", color: "#000000", marginTop: "4%" }}
        >
          <a href="/privacypolicy">Privacy Policy</a>
        </Row>
        <Row
          className="link-footer"
          style={{ fontSize: "18px", color: "#000000" }}
        >
          <a href="/cookiespolicy">Cookies Policy</a>
        </Row>
        <Row
          className="link-footer"
          style={{ fontSize: "18px", color: "#000000" }}
        >
          <a href="/termsandconditions">Terms and Condition</a>
        </Row>
        <Row className="closing-footer" style={{fontSize: "18px", marginTop: "8%" }}>
          Ependisis.com © 2024
        </Row>
      </Col>
      <Col className="d-flex align-items-center justify-content-center">
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div>
      </Col>
      <Col className="d-flex flex-column justify-content-start">
        <Row
          className="info-footer"
          style={{ fontSize: "18px", color: "#000000" }}
        >
          <a href="mailto:info@ependisis.com">info@ependisis.com</a>
        </Row>
        <Row
          className="info-footer"
          style={{ fontSize: "18px", color: "#000000", marginTop: "4%" }}
        >
          <a href="https://maps.app.goo.gl/HTgpzWAnNNJzLesU6">Gladstonos 31</a>
        </Row>
        <Row
          className="info-footer"
          style={{ fontSize: "18px", color: "#000000" }}
        >
          <a href="https://maps.app.goo.gl/HTgpzWAnNNJzLesU6">1095 Nicosia</a>
        </Row>
        <Row
          className="info-footer"
          style={{ fontSize: "18px", color: "#000000" }}
        >
          Cyprus
        </Row>
        <Row
          className="closing-footer"
          style={{ marginTop: "8%", marginLeft: "auto" }}
        >
          {/* Ependisis.com © 2024 */}
        </Row>
      </Col>
    </Row>
  );
};

export default Footer;
