import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const BackButton: React.FC = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Button variant="link" onClick={handleBack} style={{ display: "flex", paddingLeft:'10%'}}>
      <FontAwesomeIcon icon={faArrowLeft} style={{color:"#222222"}}/>
    </Button>
  );
};

export default BackButton;
