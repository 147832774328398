import { AnsweredQuestionnaire } from "../interfaces/pages/Questionnaire";
import {countries} from 'countries-list';

const countryList = Object.values(countries);

export const questionnaire: AnsweredQuestionnaire[] = [
    {
      id: 1,
      answer: [],
      questionnaire: {
        question: "Client Type",
        instruction:
          "Please provide your client classification from the list below:",
        answers: [
          { answer: "High Net Worth", order: 1, id: 1 },
          { answer: "Institutional", order: 2, id: 2 },
          { answer: "Investment Fund", order: 3, id: 3 },
          { answer: "Brokerage", order: 4, id: 4 },
        ],
        order: 1,
      },
      role: "Buyer",
      type: "Dropdown",
      required: true,
      filter:true
    },
    {
      id: 2,
      answer: [],
      questionnaire: {
        question: "Which sectors are of interest?",
        instruction:
          "Please select all the relevant sectors that may interest you",
        answers: [
          { answer: "Energy", order: 1, id: 1 },
          { answer: "Materials", order: 2, id: 2 },
          { answer: "Industrials", order: 3, id: 3 },
          { answer: "Consumer Discretionary", order: 4, id: 4 },
          { answer: "Consumer Staples", order: 5, id: 5 },
          { answer: "Health Care", order: 6, id: 6 },
          { answer: "Financials", order: 7, id: 7 },
          { answer: "IT", order: 8, id: 8 },
          { answer: "Communication Services", order: 9, id: 9 },
          { answer: "Utilities", order: 10, id: 10 },
          { answer: "Real State", order: 11, id: 11 },
        ],
        order: 2,
      },
      role: "Buyer",
      type: "Multiple",
      required: true,
      filter:true
    },
    {
      id: 3,
      answer: [],
      questionnaire: {
        question: "Asset class and strategy of interest?",
        instruction:
          "Please provide information about the type of deal you are looking to get involved in",
        answers: [
          { answer: "Equity", order: 1, id: 1 },
          { answer: "Debt", order: 2, id: 2 },
          { answer: "Hedge Funds", order: 3, id: 3 },
        ],
        order: 3,
      },
      role: "Buyer",
      type: "Multiple",
      required: true,
      filter:true
    },
    {
      id: 4,
      answer: [],
      questionnaire: {
        question: "Ideal Transaction Size",
        order: 4,
      },
      role: "Buyer",
      type: "Range",
      required: true,
      filter:true
    },
    {
      id: 5,
      answer: [],
      questionnaire: {
        question: "Ideal investment returns IRR %",
        order: 5,
      },
      role: "Buyer",
      type: "Range",
      required: true,
      filter:true
    },
    {
      id: 6,
      answer: [],
      questionnaire: {
        question: "Ideal trailing twelve-month (TTM) revenue range?",
        order: 6,
      },
      role: "Buyer",
      type: "Range",
      required: true,
      filter:true
    },
    {
      id: 7,
      answer: [],
      questionnaire: {
        question: "Ideal trailing twelve-month (TTM) profit range?",
        order: 7,
      },
      role: "Buyer",
      type: "Range",
      required: true,
      filter:true
    },
    {
      id: 8,
      answer: [],
      questionnaire: {
        question: "Which countries of risk are of interest?",
        order: 8,
        answers: countryList.map((country, index) => ({
          id: index + 1,
          answer: country.name,
          order: index + 1,
        })),
      },
      role: "Buyer",
      type: "TrueDropdown",
      required: true,
      filter:true
    },
    {
      id: 9,
      answer: [],
      questionnaire: {
        question: "Existing Net Worth or Assets Under Management",
        order: 9,
      },
      role: "Buyer",
      type: "Range",
      required: true,
      filter:true
    },
    {
      id: 10,
      answer: [],
      questionnaire: {
        question: "Existing sectors you operate in",
        answers: [
          { answer: "Energy", order: 1, id: 1 },
          { answer: "Materials", order: 2, id: 2 },
          { answer: "Industrials", order: 3, id: 3 },
          { answer: "Consumer Discretionary", order: 4, id: 4 },
          { answer: "Consumer Staples", order: 5, id: 5 },
          { answer: "Health Care", order: 6, id: 6 },
          { answer: "Financials", order: 7, id: 7 },
          { answer: "IT", order: 8, id: 8 },
          { answer: "Communication Services", order: 9, id: 9 },
          { answer: "Utilities", order: 10, id: 10 },
          { answer: "Real State", order: 11, id: 11 },
        ],
        order: 10,
      },
      role: "Buyer",
      type: "Multiple",
      required: true,
      filter:true
    },
    {
      id: 11,
      answer: [],
      questionnaire: {
        question: "Registration number",
        order: 11,
      },
      role: "Seller",
      type: "Textfield",
      required: true
    },
    {
      id: 12,
      answer: [],
      questionnaire: {
        question: "How would you describe your business?",
        order: 12,
      },
      role: "Seller",
      type: "Textarea",
      required: false
    },
    {
      id: 13,
      answer: [],
      questionnaire: {
        question: "Business website (URL)",
        order: 13,
      },
      role: "Seller",
      type: "Textfield",
      required: false
    },
    {
      id: 14,
      answer: [],
      questionnaire: {
        question: "How much revenue have you made in the last 12 months?",
        order: 14,
      },
      role: "Seller",
      type: "TextfieldNumber",
      required: false,
      filter:true
    },
    {
      id: 15,
      answer: [],
      questionnaire: {
        question: "How much profit have you made in the last 12 months?",
        order: 15,
      },
      role: "Seller",
      type: "TextfieldNumber",
      required: false,
      filter:true
    },
    {
      id: 16,
      answer: [],
      questionnaire: {
        question: "What is the total Debt of the business currently outstanding?",
        order: 16,
      },
      role: "Seller",
      type: "TextfieldNumber",
      required: false,
      filter:true
    },
    {
      id: 17,
      answer: [],
      questionnaire: {
        question: "How many employees does your company have?",
        order: 17,
      },
      role: "Seller",
      type: "TextfieldNumber",
      required: false,
      filter:true
    },
    
    {
      id: 18,
      answer: [],
      questionnaire: {
        question: "Asking price starting point (Minimum)",
        order: 18,
      },
      role: "Seller",
      type: "TextfieldNumber",
      required: false,
      filter:true
    },
    
    {
      id: 19,
      answer: [],
      questionnaire: {
        question: "Specific requirements",
        order: 19,
      },
      role: "Seller",
      type: "Textarea",
      required: false
    },
  ];