import React, { useEffect, useState } from "react";
import { addUserInfo, UserInfo, addToken } from "../../redux/UserInfoReducer";
import { RootState } from "../../redux/Store";
import { useDispatch, useSelector } from "react-redux";
import secureLocalStorage from "react-secure-storage";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../styles/admin/Dashboard.css";
import DataTable, { TableColumn } from "react-data-table-component";
import SortIcon from "@mui/icons-material/ArrowDownward";
import DeleteIcon from "@mui/icons-material/Delete";
import { DashboardEditModal, DashboardDeleteModal } from "./DashboardModal";
import { FileWithPath } from "react-dropzone";
import EditIcon from "@mui/icons-material/Edit";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextareaAutosize,
  Button,
  Grid,
  FormLabel,
  SelectChangeEvent,
  IconButton,
  Checkbox,
} from "@mui/material";
import { DashboardData } from "../../interfaces/pages/Dashboard"; // Import your models
import { DashboardTableModel } from "../../interfaces/pages/DashboardTableModel";
import IconSelection from "./IconSelection";
import FileDropzone from "./FileDropzone";

const Dashboard: React.FC = () => {

  return (
    <Grid container spacing={3}>
       <Grid item sm={12} md={4}> <h4>Dashboard</h4></Grid>
    </Grid>
  );
};

export default Dashboard;
