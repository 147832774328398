import React, { useEffect, useState } from "react";
import { GetCustomerQuestionByUser } from '../../api/Customer.Api';
import { CustomerQuestions } from "../../models/Customers";
import { RootState } from "../../../redux/Store";
import { useSelector } from "react-redux";
import Spinner from "../../components/Spinner";
import {
    Paper
} from "@mui/material";

interface CustomerDetails {
    id: number;
}
const CustomerQuestion: React.FC<CustomerDetails> = ({ id }) => {
    const [loading, setLoading] = React.useState(true);
    const loggedInUser = useSelector((state: RootState) => state.user);
    const [questionList, setQuestionList] = useState<CustomerQuestions[]>([{
        id: 0,
        question: "",
        answer: ""
    }]);


    useEffect(() => {
        handleCustomersList();
    }, []);

    const handleCustomersList = async () => {
        setLoading(true);
        const questions = await GetCustomerQuestionByUser(loggedInUser.token, id);
        if (questions.status == "200") {
            if (questions.data.length > 0)
                setQuestionList(questions.data);
        }
        setLoading(false);
    }

    return (


        <div style={{ padding: "0", width: "100%" }}>
            {loading ?
                <Spinner /> : ""}
            {questionList.map((quest) => (
                <Paper elevation={3}>
                    <div style={{ margin: "10px", padding: "2px" }}>
                        <div style={{ fontWeight: "bold" }}>Q: {quest.question}</div>
                        {quest.answer.split(';').length > 0 ?

                            quest.answer.split(';').map((ans) => (
                                <div>A : {ans}</div>
                            ))

                            :
                            <div>A : {quest.answer}</div>
                        }
                    </div>
                </Paper>

            ))}
        </div>

    );
}


export default CustomerQuestion;