import { configureStore } from "@reduxjs/toolkit";
import UserInfoReducer from "./UserInfoReducer";
import RolePackageReducer from "./RolePackageReducer";
export const store = configureStore({
    reducer: {
        user: UserInfoReducer,
        rolePackage: RolePackageReducer,
    }
})
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch