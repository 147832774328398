export class SendContactFormRequest {
  fullName: string;
  email: string;
  phone: string;
  message: string;

  constructor(data: {
    fullName: string;
    email: string;
    phone: string;
    message: string;
  }) {
    this.fullName = data.fullName;
    this.email = data.email;
    this.phone = data.phone;
    this.message = data.message;
  }
}
