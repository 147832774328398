import settings from '../../settings';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { CustomersData, CustomerQuestions } from '../models/Customers';

const EpendisisApi: AxiosInstance = axios.create({
    baseURL: settings.Functions.EpendisisApi,
});


export const GetAllCustomers = async (token: string, questionId: number, answer: string, type: string, min: number, max: number): Promise<any> => {
    try {
        EpendisisApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const data = {
            questionId: questionId,
            answer: answer,
            type: type,
            min: min,
            max: max
        }
        const response: AxiosResponse<any> = await EpendisisApi.post('/api/getAllCustomers', data);
        return response;
    } catch (error: any) {
        return error?.response;
    }
}

export const GetAllCustomerById = async (token: string, userId: number): Promise<any> => {
    try {
        EpendisisApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response: AxiosResponse<CustomersData> = await EpendisisApi.get('/api/getCustomerById/' + userId);
        return response;
    } catch (error: any) {
        return error?.response;
    }
}

export const UpdateCustomer = async (token: string, details: CustomersData): Promise<any> => {
    try {
        EpendisisApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response: AxiosResponse<any> = await EpendisisApi.post('/api/updateCustomerStatus', details);
        return response;
    } catch (error: any) {
        return error?.response;
    }
}


export const GetCustomerDocumentByUser = async (token: string, userId: number): Promise<any> => {
    try {
        EpendisisApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response: AxiosResponse<any> = await EpendisisApi.get('/api/getCustomerDocuments/' + userId);
        return response;
    } catch (error: any) {
        return error?.response;
    }
}

export const DownloadDocuments = async (token: string, data: any): Promise<any> => {
    try {
        EpendisisApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response: AxiosResponse<any> = await EpendisisApi.post('/api/downloadCustomerDocuments', data);
        return response;
    } catch (error: any) {
        return error?.response;
    }
}

export const GetCustomerQuestionByUser = async (token: string, userId: number): Promise<any> => {
    try {
        EpendisisApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response: AxiosResponse<CustomerQuestions[]> = await EpendisisApi.get('/api/getQuestionsByUser/' + userId);
        return response;
    } catch (error: any) {
        return error?.response;
    }
}

export const SendeEmailForStatus = async (token: string, userId: number, status: string): Promise<any> => {
    try {
        EpendisisApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response: AxiosResponse<any> = await EpendisisApi.get('/api/SendAccountStatusToUser/' + userId + '/'+status);
        return response;
    } catch (error: any) {
        return error?.response;
    }
}

export const SendeEmailToAdmin = async (token: string, userId: number): Promise<any> => {
    try {
        EpendisisApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response: AxiosResponse<any> = await EpendisisApi.get('/api/sendConfirmationToAdmin/' + userId);
        return response;
    } catch (error: any) {
        return error?.response;
    }
}

export const GetNewsLetter = async (token: string): Promise<any> => {
    try {
        EpendisisApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response: AxiosResponse<any> = await EpendisisApi.get('/api/getAllNewsletter');
        return response;
    } catch (error: any) {
        return error?.response;
    }
}


export const GetProductsApproval = async (userID: number, token: string): Promise<any> => {
    try {
        EpendisisApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response: AxiosResponse<any> = await EpendisisApi.get('/api/getApprovalDocumentbyUser/' + userID);
        return response;
    } catch (error: any) {
        return error?.response;
    }
}

export const UpdateProductsApproval = async (id: number, status: number, token: string): Promise<any> => {
    try {
        EpendisisApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response: AxiosResponse<any> = await EpendisisApi.put('/api/UpdateDocumentAccessStatus/' + id + '/' + status);
        return response;
    } catch (error: any) {
        return error?.response;
    }
}