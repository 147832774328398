import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";

import Footer from "../components/Footer/Footer";
import HeaderMenu from "../components/HeaderMenu/HeaderMenu";
import ContactUs from "../components/ContactUs/ContactUs";

function TermsAndConditions() {
  useEffect(() => {
    document.title = "Terms and Conditions";
  }, []);

  return (
    <Container fluid>
      <Row
        style={{
          backgroundColor: "#242A49",
        }}
        className="justify-content-md-center"
      >
        <HeaderMenu />
      </Row>
      <Row
        style={{
          backgroundColor: "#FAFAFA",
          paddingLeft: "10%",
          paddingRight: "10%",
          paddingTop: "80px",
        }}
      >
        <Col>
          <p>Updated Date: 18/05/2023</p>
        </Col>
      </Row>
      {/* Cookies Policy Content */}
      <Row
        style={{
          backgroundColor: "#FAFAFA",
          paddingLeft: "10%",
          paddingRight: "10%",
        }}
      >
        <Col>
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            Acceptance of the Terms of Use
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            These Terms of Use establish an agreement between You and Lagonem
            Investments Ltd (&quot;Company,&quot; &quot;Lagonem,&quot;
            &quot;Ependisis,&quot; &quot;we,&quot; or &quot;us&quot;). They
            govern your use of the website located at www.ependisis.com
            (the &quot;Website&quot;), including its content, features, and
            services. Whether you are a guest or a registered user, it is
            important that you carefully read and agree to these Terms of Use,
            along with our Privacy Policy available at 
             <a href="https://www.Ependisis.com/privacypolicy">
                 Privacy Policy
              </a>, which is incorporated by
            reference. If you do not wish to accept these Terms of Use or the
            Privacy Policy, you should refrain from accessing or using the
            Website.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            The Website is intended for individuals who are 16 years of age or
            older. By accessing and using this Website, you affirm that you are
            of legal age to enter into a binding agreement with the Company and
            that you meet all the necessary eligibility criteria mentioned
            above. If you do not meet these requirements, you should not access
            or use the Website.
          </p>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            The Website's Services
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            Our website is an online marketplace that connects sellers of
            businesses to potential buyers of businesses. The information
            provided on the Website is for our users’ internal business purposes
            only.
          </p>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            What the Website DOESN’T Do
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            Ependisis, as an entity, does not engage in the purchase or sale of
            any businesses listed on the Website. The content on our Website
            does not provide any financial, legal, or similar advice or
            services. We are not a broker and do not perform broker-related
            tasks. Ependisis and the Website should not be considered a
            substitute for the guidance or services of a broker.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            The agreement between a buyer and seller regarding a business
            transaction is solely between those parties. Ependisis is not
            involved in the transaction and bears no responsibility for the sale
            of any businesses featured on the Website. We assume no liability or
            obligation arising from or in connection with such sales.
          </p>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            Listings
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            Ependisis engages in discussions with potential sellers to verify
            the authenticity of the listings on the Website. However, Ependisis
            cannot guarantee the suitability of a listing for potential buyers
            or whether the listed businesses will meet a buyer&#39;s performance
            expectations. Ependisis bears no responsibility for the accuracy,
            quality, completeness, or truthfulness of any listings on the
            Website, as provided by the sellers.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            As a seller, it is essential to provide Ependisis with comprehensive
            and accurate information about your business. Ependisis reserves the
            right, at its sole discretion, to modify or remove any listing if it
            determines that the information provided is incomplete or
            inaccurate.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "20px" }}
          >
            For each listing you provide, you represent and warrant to Ependisis
            that:
          </p>
          <ul
            style={{
              textAlign: "justify",
              listStyleType: "none",
              marginBottom: "40px",
            }}
          >
            <li>
              (a) You are the owner of the business, or are otherwise entitled
              to sell the business;
            </li>
            <li>
              (b) You are the owner of all intellectual property rights
              associated with the business, or the authorized licensee of the
              same;
            </li>
            <li>(c) The business may be legally transferable to a buyer;</li>
            <li>
              (d) Any accompanying agreements of the business with third-parties
              are also transferable to the buyer.
            </li>
          </ul>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            User Responsibilities
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            Buying or selling a business requires investigation and due
            diligence. As a buyer, you are solely responsible for researching
            any potential purchase. As a seller, you are responsible for
            ensuring the buyer is in a position to purchase the business.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            It is your responsibility to consult with tax and legal counsel
            before undertaking any purchase or sale of a business.
          </p>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            Changes to the Terms of Use
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            We may revise and update these Terms of Use from time to time in our
            sole discretion. All changes are effective immediately when we post
            them.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            Your continued use of the Website following the posting of revised
            Terms of Use means that you accept and agree to the changes. You are
            expected to check this page from time to time so you are aware of
            any changes, as they are binding on you.
          </p>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            Accessing the Website and Account Security
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            We retain the right to withdraw or modify this Website, as well as
            any services or materials provided on the Website, at our sole
            discretion without prior notice. We shall not be held liable if, for
            any reason, the Website or any portion of it becomes unavailable at
            any time or for any duration. Occasionally, we may limit access to
            specific sections of the Website or the entire Website to certain
            users, including registered users.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "20px" }}
          >
            You are responsible for:
          </p>
          <ul
            style={{
              marginBottom: "20px",
              textAlign: "justify",
            }}
          >
            <li>Making all necessary arrangements to access the Website.</li>
            <li>
              Ensuring that anyone accessing the Website through your internet
              connection is aware of and complies with these Terms of Use.
            </li>
          </ul>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            In order to access the Website or utilize certain resources it
            offers, you may be required to provide registration details or other
            information. It is a condition of your use of the Website that all
            information you provide is accurate, up-to-date, and complete. By
            registering with this Website or using any interactive features on
            the Website, you agree that the collection and use of your
            information are governed by our Privacy Policy (available at 
              <a href="https://www.Ependisis.com/privacypolicy">
                Privacy Policy
              </a>).
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            If you choose or are provided with a username, password, or any
            other information as part of our security measures, you must keep
            such information confidential and refrain from disclosing it to any
            other individual or entity. You acknowledge that your account is
            personal to you and agree not to grant access to this Website or any
            part of it to any other person using your username, password, or
            other security details. You agree to promptly notify us of any
            unauthorized access or use of your username, password, or any other
            security breach. It is also your responsibility to ensure that you
            log out of your account at the end of each session. Exercise caution
            when accessing your account from public or shared computers to
            prevent others from viewing or recording your password or personal
            information.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            We reserve the right to disable any username, password, or other
            identifier, whether chosen by you or provided by us, at any time and
            for any reason at our sole discretion, including if we believe you
            have violated any provision of these Terms of Use.
          </p>

          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            Accessing the Website and Account Security
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            The Company, its licensors, or other providers of the material own
            the Website, including all its contents, features, and functionality
            (such as information, software, text, displays, images, video and
            audio, and their design and arrangement). These materials are
            protected by copyright, trademark, patent, trade secret, and other
            intellectual property or proprietary rights laws in the Republic of
            Cyprus and internationally.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "20px" }}
          >
            These Terms of Use allow you to use the Website for personal,
            non-commercial purposes only. You are prohibited from reproducing,
            distributing, modifying, creating derivative works, publicly
            displaying or performing, republishing, downloading, storing, or
            transmitting any material from the Website, except in the following
            cases:
          </p>
          <ul
            style={{
              marginBottom: "20px",
              textAlign: "justify",
            }}
          >
            <li>
              Temporary storage in RAM by your computer when accessing and
              viewing the materials.
            </li>
            <li>
              Storage of files automatically cached by your web browser for
              display enhancement purposes.
            </li>
            <li>
              Printing or downloading one copy of a reasonable number of pages
              from the Website for your personal, non-commercial use, without
              further reproduction, publication, or distribution.
            </li>
            <li>
              Downloading a single copy of desktop, mobile, or other
              applications provided by us, solely for your personal,
              non-commercial use, subject to our end user license agreement for
              such applications.
            </li>
          </ul>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "20px" }}
          >
            You are not permitted to:
          </p>
          <ul
            style={{
              marginBottom: "20px",
              textAlign: "justify",
            }}
          >
            <li>Modify copies of any materials from the site.</li>
            <li>
              Use any illustrations, photographs, video or audio sequences, or
              graphics separately from the accompanying text.
            </li>
            <li>
              Remove or alter any copyright, trademark, or other proprietary
              rights notices from material copies.
            </li>
          </ul>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            You must not access or use any part of the Website or its services
            or materials for commercial purposes. If you want to use material
            from the Website beyond what is stated in this section, please
            contact us at{" "}
            <b>
              <i>info@Ependisis.com</i>
            </b>
            .
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            If you violate the Terms of Use by printing, copying, modifying,
            downloading, or providing access to any part of the Website, your
            right to use the Website will immediately cease. At our discretion,
            you must either return or destroy any copies of the materials you
            have made. You are not granted any right, title, or interest in the
            Website or its content, and all rights not expressly granted are
            reserved by the Company. Unauthorized use of the Website, not
            expressly permitted by these Terms of Use, is a breach of these
            terms and may infringe upon copyright, trademark, and other laws.
          </p>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            Trademarks
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            The Company name and all related names, logos, product and service
            names, designs and slogans are trademarks of the Company or its
            affiliates or licensors. You must not use such marks without the
            prior written permission of the Company. All other names, logos,
            product and service names, designs and slogans on this Website are
            the trademarks of their respective owners.
          </p>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            Prohibited Uses
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "20px" }}
          >
            You may use the Website only for lawful purposes and in accordance
            with these Terms of Use. You agree not to use the Website:
          </p>
          <ul style={{ marginBottom: "40px" }}>
            <li>
              In any way that violates any applicable European, local, or
              international law or regulation.
            </li>
            <li>
              For the purpose of exploiting, harming, or attempting to exploit
              or harm minors in any way by exposing them to inappropriate
              content, asking for personally identifiable information, or
              otherwise.
            </li>
            <li>
              To send, knowingly receive, upload, download, use, or re-use any
              material which does not comply with the set out in these Terms of
              Use Content Standards.
            </li>
            <li>
              To transmit or procure the sending of any advertising or
              promotional material, including any "junk mail," "chain letter,"
              "spam," or any other similar solicitation.
            </li>
            <li>
              To impersonate or attempt to impersonate the Company, a Company
              employee, another user, or any other person or entity (including,
              without limitation, by using e-mail addresses or screen names
              associated with any of the foregoing).
            </li>
            <li>
              To engage in any other conduct that restricts or inhibits anyone's
              use or enjoyment of the Website, or which, as determined by us,
              may harm the Company or users of the Website, or expose them to
              liability.
            </li>
          </ul>

          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "20px" }}
          >
            Additionally, you agree not to:
          </p>
          <ul style={{ marginBottom: "40px" }}>
            <li>
              Use the Website in any manner that could disable, overburden,
              damage, or impair the site or interfere with any other party's use
              of the Website, including their ability to engage in real-time
              activities through the Website.
            </li>
            <li>
              Use any robot, spider, or other automatic device, process, or
              means to access the Website for any purpose, including monitoring
              or copying any of the material on the Website.
            </li>
            <li>
              Use any manual process to monitor or copy any of the material on
              the Website, or for any other purpose not expressly authorized in
              these Terms of Use, without our prior written consent.
            </li>
            <li>
              Use any device, software, or routine that interferes with the
              proper working of the Website.
            </li>
            <li>
              Introduce any viruses, trojan horses, worms, logic bombs, or other
              material which is malicious or technologically harmful.
            </li>
            <li>
              Attempt to gain unauthorized access to, interfere with, damage, or
              disrupt any parts of the Website, the server on which the Website
              is stored, or any server, computer, or database connected to the
              Website.
            </li>
            <li>
              Attack the Website via a denial-of-service attack or a distributed
              denial-of-service attack.
            </li>
            <li>
              Otherwise attempt to interfere with the proper working of the
              Website.
            </li>
          </ul>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            User Contributions
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            The Website may contain message boards, chat rooms, personal web
            pages or profiles, forums, bulletin boards, and other interactive
            feature (collectively,{" "}
            <b>
              <i>&quot;Interactive Services&quot;</i>
            </b>
            ) that allow users to post, submit, publish, display or transmit to
            other users or other persons (hereinafter,{" "}
            <b>
              <i>&quot;post&quot;</i>
            </b>
            ) content or materials (collectively,{" "}
            <b>
              <i>&quot;User Contributions&quot;</i>
            </b>
            ) on or through the Website.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            All User Contributions must comply with the Content Standards set
            out in these Terms of Use.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            Any User Contribution you post to the site will be considered
            non-confidential and non-proprietary. By providing any User
            Contribution on the Website, you grant us and our affiliates and
            service providers, and each of their and our respective licensees,
            successors and assigns the right to use, reproduce, modify, perform,
            display, distribute and otherwise disclose to third parties any such
            material according to your account settings.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "20px" }}
          >
            You represent and warrant that:
          </p>
          <ul style={{ marginBottom: "40px" }}>
            <li>
              You own or control all rights in and to the User Contributions and
              have the right to grant the license granted above to us and our
              affiliates and service providers, and each of their and our
              respective licensees, successors, and assigns.
            </li>
            <li>
              All of your User Contributions do and will comply with these Terms
              of Use.
            </li>
          </ul>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            You understand and acknowledge that you are responsible for any User
            Contributions you submit or contribute, and you, not the Company,
            have full responsibility for such content, including its legality,
            reliability, accuracy and appropriateness.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            We are not responsible, or liable to any third party, for the
            content or accuracy of any User Contributions posted by you or any
            other user of the Website.
          </p>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            Monitoring and Enforcement; Termination
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "20px" }}
          >
            We have the right to:
          </p>
          <ul style={{ marginBottom: "40px" }}>
            <li>
              Remove or refuse to post any User Contributions for any or no
              reason in our sole discretion.
            </li>
            <li>
              Take any action with respect to any User Contribution that we deem
              necessary or appropriate in our sole discretion, including if we
              believe that such User Contribution violates the Terms of Use,
              including the Content Standards, infringes any intellectual
              property right or other right of any person or entity, threatens
              the personal safety of users of the Website or the public, or
              could create liability for the Company.
            </li>
            <li>
              Disclose your identity or other information about you to any third
              party who claims that material posted by you violates their
              rights, including their intellectual property rights or their
              right to privacy.
            </li>
            <li>
              Take appropriate legal action, including without limitation,
              referral to law enforcement, for any illegal or unauthorized use
              of the Website.
            </li>
            <li>
              Terminate or suspend your access to all or part of the Website for
              any or no reason, including without limitation, any violation of
              these Terms of Use.
            </li>
          </ul>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            Without limiting the foregoing, we have the right to fully cooperate
            with any law enforcement authorities or court order requesting or
            directing us to disclose the identity or other information of anyone
            posting any materials on or through the Website. YOU WAIVE AND HOLD
            HARMLESS THE COMPANY AND ITS AFFILIATES, LICENSEES AND SERVICE
            PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF
            THE FOREGOING PARTIES DURING OR AS A RESULT OF ITS INVESTIGATIONS
            AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY
            EITHER SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            However, we cannot review all material before it is posted on the
            Website and cannot ensure prompt removal of objectionable material
            after it has been posted. Accordingly, we assume no liability for
            any action or inaction regarding transmissions, communications or
            content provided by any user or third party. We have no liability or
            responsibility to anyone for performance or nonperformance of the
            activities described in this section.
          </p>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            Content Standards
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "20px" }}
          >
            We have the right to:
          </p>
          <ul style={{ marginBottom: "40px" }}>
            <li>
              Contain any material which is defamatory, obscene, indecent,
              abusive, offensive, harassing, violent, hateful, inflammatory, or
              otherwise objectionable.
            </li>
            <li>
              Promote sexually explicit or pornographic material, violence, or
              discrimination based on race, sex, religion, nationality,
              disability, sexual orientation, or age.
            </li>
            <li>
              Infringe any patent, trademark, trade secret, copyright, or other
              intellectual property or other rights of any other person.
            </li>
            <li>
              Violate the legal rights (including the rights of publicity and
              privacy) of others or contain any material that could give rise to
              any civil or criminal liability under applicable laws or
              regulations or that otherwise may be in conflict with these Terms
              of Use and our 
              <a href="https://www.Ependisis.com/privacypolicy">
                Privacy Policy
              </a>
              .
            </li>
            <li>Be likely to deceive any person.</li>
            <li>
              Promote any illegal activity, or advocate, promote, or assist any
              unlawful act.
            </li>
            <li>
              Cause annoyance, inconvenience, or needless anxiety or be likely
              to upset, embarrass, alarm, or annoy any other person.
            </li>
            <li>
              Impersonate any person, or misrepresent your identity or
              affiliation with any person or organization.
            </li>
            <li>
              Involve commercial activities or sales, such as contests,
              sweepstakes, and other sales promotions, barter, or advertising.
            </li>
            <li>
              Give the impression that they emanate from or are endorsed by us
              or any other person or entity if this is not the case.
            </li>
          </ul>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            Copyright Infringement
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            If you believe that any User Contributions violate your copyright,
            please email a notice of copyright infringement to
            info@Ependisis.com. It is the policy of the Company to terminate
            the user accounts of repeat infringers.
          </p>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            Reliance on Information Posted
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            The information presented on or through the Website is made
            available solely for general information purposes. We do not warrant
            the accuracy, completeness or usefulness of this information. Any
            reliance you place on such information is strictly at your own risk.
            We fully disclaim all liability and responsibility arising from any
            reliance placed on such materials by you or any other visitor to the
            Website, or by anyone who may be informed of any of its contents.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            This Website may include content provided by third parties,
            including materials provided by other users, bloggers and
            third-party licensors, syndicators, aggregators and/or reporting
            services. All statements and/or opinions expressed in these
            materials, and all articles and responses to questions and other
            content, other than the content provided by the Company, are solely
            the opinions and the responsibility of the person or entity
            providing those materials. These materials do not necessarily
            reflect the opinion of the Company. We are not responsible, or
            liable to you or any third party, for the content or accuracy of any
            materials provided by any third parties.
          </p>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            Changes to the Website
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            We may update the content on this Website from time to time, but its
            content is not necessarily complete or up-to-date. Any of the
            material on the Website may be out of date at any given time, and we
            are under no obligation to update such material.
          </p>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            Information About You and Your Visits to the Website
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            All information we collect on this Website is subject to our 
            <a href="https://www.Ependisis.com/privacypolicy">
                Privacy Policy
              </a>. By using the Website, you
            consent to all actions taken by us with respect to your information
            in compliance with the Privacy Policy.
          </p>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            Linking to the Website and Social Media Features
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            You may link to our homepage, provided you do so in a way that is
            fair, legal, and does not damage our reputation or take advantage of
            it, but you must not establish a link in such a way as to suggest
            any form of association, approval or endorsement on our part without
            our express consent.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "20px" }}
          >
            This Website may provide certain social media features that enable
            you to:
          </p>
          <ul style={{ marginBottom: "40px" }}>
            <li>
              Link from your own or certain third-party websites to certain
              content on this Website.
            </li>
            <li>
              Send e-mails or other communications with certain content, or
              links to certain content, on this Website.
            </li>
            <li>
              Cause limited portions of content on this Website to be displayed
              or appear to be displayed on your own or certain third-party
              websites.
            </li>
          </ul>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "20px" }}
          >
            You may use these features solely as they are provided by us, solely
            with respect to the content they are displayed with, and otherwise
            in accordance with any additional terms and conditions we provide
            with respect to such features. Subject to the foregoing, you must
            not:
          </p>
          <ul style={{ marginBottom: "40px" }}>
            <li>Establish a link from any website that is not owned by you.</li>
            <li>
              Cause the Website or portions of it to be displayed, or appear to
              be displayed by, for example, framing, deep linking, or in-line
              linking, on any other site.
            </li>
            <li>Link to any part of the Website other than the homepage.</li>
            <li>
              Otherwise take any action with respect to the materials on this
              Website that is inconsistent with any other provision of these
              Terms of Use.
            </li>
          </ul>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            The website from which you are linking, or on which you make certain
            content accessible, must comply in all respects with the Content
            Standards set out in these Terms of Use.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            You agree to cooperate with us in causing any unauthorized framing
            or linking immediately to cease. We reserve the right to withdraw
            linking permission without notice.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            We may disable all or any social media features and any links at any
            time without notice in our sole discretion.
          </p>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            Links from the Website
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            If the Website contains links to other sites and resources provided
            by third parties, these links are provided for your convenience
            only. This includes links contained in advertisements, including
            banner advertisements and sponsored links. We have no control over
            the contents of those sites or resources, and accept no
            responsibility for them or for any loss or damage that may arise
            from your use of them. If you decide to access any of the third
            party websites linked to this Website, you do so entirely at your
            own risk and subject to the terms and conditions of use for such
            websites.
          </p>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            Geographic Restrictions
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            The owner of the Website is based in Nicosia Cyprus . We provide
            this Website for use only by persons located within the European
            Union. We make no claims that the Website or any of its content is
            accessible or appropriate outside of the European Union. Access to
            the Website may not be legal by certain persons or in certain
            countries. If you access the Website from outside the European
            Union, you do so on your own initiative and are responsible for
            compliance with local laws.
          </p>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            Disclaimer of Warranties
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            You understand that we cannot and do not guarantee or warrant that
            the Website will be free of viruses or other destructive code. You
            are responsible for implementing sufficient procedures and
            checkpoints to satisfy your particular requirements for anti-virus
            protection and accuracy of data input and output, and for
            maintaining a means external to our site for any reconstruction of
            any lost data. WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED
            BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER
            TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER
            EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE
            TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH
            THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR
            ON ANY WEBSITE LINKED TO IT.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            YOUR USE OF THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS
            OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS
            CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE
            PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS,
            WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED.
            NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES
            ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS,
            SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE
            WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR
            ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE
            WEBSITE, ITS CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
            WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED,
            THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT
            MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS
            OR THAT THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
            WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
            EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT
            LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND
            FITNESS FOR PARTICULAR PURPOSE.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE
            EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
          </p>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            Limitation on Liability
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY,
            ITS AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES,
            AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND,
            UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR
            USE, OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT,
            ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES OR ANY SERVICES OR
            ITEMS OBTAINED THROUGH THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING
            ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE
            DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND
            SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS,
            LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF
            GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING
            NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED
            OR LIMITED UNDER APPLICABLE LAW.
          </p>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            Indemnification
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            You agree to defend, indemnify and hold harmless the Company, its
            affiliates, licensors and service providers, and its and their
            respective officers, directors, employees, contractors, agents,
            licensors, suppliers, successors and assigns from and against any
            claims, liabilities, damages, judgments, awards, losses, costs,
            expenses or fees (including reasonable attorneys&#39; fees) arising
            out of, relating to, or in connection with your violation of these
            Terms of Use or your use of the Website, including, but not limited
            to, your User Contributions, any use of the Website&#39;s content,
            services and products other than as expressly authorized in these
            Terms of Use, or your use of any information obtained from the
            Website.
          </p>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            Termination
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            The Website contains information on how to terminate your account.
            If you have purchased use of our services for a specific term, such
            termination will be effective on the last day of the then-current
            term. Your order form may provide that a renewal term will begin
            automatically unless either party provides notice of termination at
            least thirty (30) days prior to the commencement of the next renewal
            term. If you fail to comply with any provision of this Terms of Use,
            Company may terminate this Terms of Use immediately and retain any
            fees previously paid by you. Upon any termination of this Terms of
            Use, you must cease any further use of the Website. If at any time
            you are not happy with the Website, your sole remedy is to cease
            using the services and follow this termination process. Any
            provisions in this Terms of Use which are required to fulfill their
            essential purpose shall survive termination of this Terms of Use.
          </p>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            Governing Law and Jurisdiction
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            All matters relating to the Website and these Terms of Use, and any
            dispute or claim arising therefrom or related thereto (in each case,
            including non- contractual disputes or claims), shall be governed by
            and construed in accordance with the laws of the Republic of Cyprus.
          </p>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            Limitation on Time to File Claims
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING
            TO THESE TERMS OF USE OR THE WEBSITE MUST BE COMMENCED WITHIN ONE
            (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF
            ACTION OR CLAIM IS PERMANENTLY BARRED.
          </p>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            Waiver and Severability
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            No waiver of by the Company of any term or condition set forth in
            these Terms of Use shall be deemed a further or continuing waiver of
            such term or condition or a waiver of any other term or condition,
            and any failure of the Company to assert a right or provision under
            these Terms of Use shall not constitute a waiver of such right or
            provision.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            If any provision of these Terms of Use is held by a court or other
            tribunal of competent jurisdiction to be invalid, illegal or
            unenforceable for any reason, such provision shall be eliminated or
            limited to the minimum extent such that the remaining provisions of
            the Terms of Use will continue in full force and effect.
          </p>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            Entire Agreement
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            The Terms of Use and our Privacy Policy constitute the sole and
            entire agreement between you and the Company with respect to the
            Website and supersede all prior and contemporaneous understandings,
            agreements, representations and warranties, both written and oral,
            with respect to the Website.
          </p>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            Your Comments and Concerns
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            This website is operated by Lagonem Investments Ltd
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            All notices of copyright infringement, other feedback, comments,
            requests for technical support and other communications relating to
            the Website should be directed to: info@Ependisis.com.
          </p>
        </Col>
      </Row>      <Row className="justify-content-center">
        <ContactUs />
      </Row>
      <Row
        className="justify-content-center"
        style={{ marginLeft: "1%", marginRight: "1%", marginTop: "1%" }}
      >
        <Footer />
      </Row>
    </Container>
  );
}

export default TermsAndConditions;
