import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface RolePackageInitialState {
    role: string | null;
    package: string;
}

const initialState: RolePackageInitialState = {
    role: "",
    package: "",
};

export const rolePackageSlice = createSlice({
    name: 'RolePackage',
    initialState,
    reducers: {
        updateRole: (state, action: PayloadAction<string | null>) => {
            state.role = action.payload;
        },
        updatePackage: (state, action: PayloadAction<string>) => {
            state.package = action.payload;
        },
    },
});

export const {updateRole, updatePackage} = rolePackageSlice.actions;
export default rolePackageSlice.reducer;
