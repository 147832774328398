export class ProductImage {
    url: string;
    type: string;
    title: string;

    constructor(image: any) {
        this.url = image.url;
        this.type = image.type;
        this.title = image.title;
    }
}