import React from "react";
import PlanCardPropsNew from "../interfaces/components/PlanCard/PlanCardPropsNew";
import "./PlanCardContainer.css";
import { Col } from "react-bootstrap";
import { updatePackage, updateRole } from "../redux/RolePackageReducer";
import { useDispatch } from "react-redux";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import PlanCardNew from "../components/PlanCardNew/PlanCardNew";

function PlanCardNewContainer({ data, roleSelected }: { data: PlanCardPropsNew[], roleSelected: string }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
 
  const handleButtonClick = (role: string | null, userPackage: string) => {
    secureLocalStorage.setItem("role", role ?? "");
    secureLocalStorage.setItem("package", userPackage);
    dispatch(updateRole(role));
    dispatch(updatePackage(userPackage));
    navigate("/signup");
  };

  return (
    <div className="plan-card-container-new">
      {data.map((item, index) => (
        <Col xs={12} sm={6} md={4} lg={3} key={index} style={{width:"30%"}}>
          <PlanCardNew key={index} {...item} buttonMethod={() => handleButtonClick(roleSelected, (index+1).toString())} />
        </Col>
      ))}
    </div>
  );
}


export default PlanCardNewContainer;
function dispatch(arg0: { payload: string | null; type: "RolePackage/updatePackage"; }) {
  throw new Error("Function not implemented.");
}

