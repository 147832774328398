import { Container } from "react-bootstrap";
import SellerIdeaProps from "../../interfaces/components/SellerIdea/SellerIdeaProps";
import "./SellerIdea.css";

const SellerIdea: React.FC<SellerIdeaProps> = ({ title, ideas }) => {
  return (
    <Container className="seller-idea border-black pt-3">
      <h2 className="seller-idea-title">{title}</h2>
      <ul className="list-unstyled">
        {ideas.map((idea, index) => (
          <>
            <li key={index} className="seller-idea-idea">
              <span className="bullet" style={{marginRight:"5px", fontSize:"smaller"}}></span>
              <em>{idea}</em>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
};

export default SellerIdea;
