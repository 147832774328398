import { Col, Row } from "react-bootstrap";
import { SummaryCardProps } from "../../interfaces/components/SummaryCard/SummaryCardProps";
import './SummaryCard.css'

const SummaryCard: React.FC<SummaryCardProps> = ({
    title,
    paragraphText,
    backgroundColor
}) => {
    return (
        <Col
            className="summary-card"
            style={{
                backgroundColor: backgroundColor
            }}
        >
            <Row>
                <p className="summary-card-text">
                    {paragraphText}
                </p>
            </Row>
            <Row style = {{marginTop:"auto"}}>
                <h3 className="summary-card-title">
                    {title}
                </h3>
            </Row>
             
        </Col>
    );
}

export default SummaryCard;