import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import Footer from "../components/Footer/Footer";
import backgroundImg from "../assets/login-bg.png";
import { Fade } from "@mui/material";
import HeaderMenu from "../components/HeaderMenu/HeaderMenu";
import userImg from "../assets/user.png";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { addToken, addUserInfo } from "../redux/UserInfoReducer";
import secureLocalStorage from "react-secure-storage";
import { RegisterResponse } from "../interfaces/models/RegisterResponse";
import {
  Register as EpendisisRegister,
  GenerateVerification as EpendisisGenVerify,
} from "../apis/EpendisisApi";
import { RegisterRequest } from "../interfaces/models/RegisterRequest";

const Signup: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userPackage, setUserPackage] = useState<string>("");
  const [userRole, setUserRole] = useState<string>("");
  // const [showModal, setShowModal] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [alertVisibility, setAlertVisibility] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    switch (name) {
      case "firstName":
        setFirstName(value);
        break;
      case "lastName":
        setLastName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "password":
        setPassword(value);
        break;
      default:
        break;
    }
  };

  const isPasswordValid = (password: string) => {
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    return passwordRegex.test(password);
  };

  const handleApiCall = async () => {
    setLoading(true);
    try {
      const response = await EpendisisRegister(
        new RegisterRequest(
          `${firstName} ${lastName}`,
          email,
          password,
          userPackage,
          userRole
        )
      );
      if (response.status == "200") {
        let registerRes: RegisterResponse = response.data;
        secureLocalStorage.setItem("token", registerRes.token);
        secureLocalStorage.setItem("user", JSON.stringify(registerRes.user));
        dispatch(addToken(registerRes.token));
        dispatch(addUserInfo(JSON.stringify(registerRes.user)));
        if (registerRes.user.roles == "Admin") {
          navigate("/admin/dashboard");
        } else {
          try {
            const verifyResponse = await EpendisisGenVerify(
              email,
              registerRes.token
            );
          } catch {}
          navigate("/onboarding");
        }
      } else {
        setErrorMsg(response.data.msg);
        setAlertVisibility(true);
      }
    } catch (error: any) {
      const errorMessage = error?.response?.data?.msg;
      if (errorMessage === "This user is already in use!") {
        setErrorMsg(
          "This email is already registered, please Login or click on 'Forgot your password'"
        );
      } else {
        setErrorMsg(errorMessage || "An unexpected error occurred");
      }
      setAlertVisibility(true);
      console.error("Error making API call:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isPasswordValid(password)) {
      handleApiCall();
    } else {
      setErrorMsg(
        "Password must be at least 8 characters long and contain both letters and numbers."
      );
      setAlertVisibility(true);
    }
  };

  useEffect(() => {
    document.title = "Signup";
    const fetchDataFromLocalStorage = () => {
      try {
        const user = secureLocalStorage.getItem("user");
        const token = secureLocalStorage.getItem("token");
        if (user !== null && token !== null) {
          navigate("/onboarding");
        }
      } catch (error) {}

      try {
        // Retrieve values from secureLocalStorage
        const storedRole = secureLocalStorage.getItem("role");
        const storedPackage = secureLocalStorage.getItem("package");

        // Dispatch actions or handle data as needed
        if (storedRole === null || storedPackage === null) {
          navigate("/");
          // setShowModal(true);
        } else {
          setUserPackage(storedPackage.toString());
          setUserRole(storedRole.toString());
        }
      } catch (error) {
        console.error("Error fetching data from secureLocalStorage:", error);
      }
    };

    fetchDataFromLocalStorage();
  }, [dispatch]);

  return (
    <Container
      fluid
      className="wrapper"
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: "0",
      }}
    >
      <div
        style={{
          backgroundImage: `url(${backgroundImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "100vh",
          display: "grid",
          flexDirection: "column",
          paddingBottom: "5%",
        }}
      >
        <HeaderMenu />
        <Container style={{ flex: "1", marginTop: "3%" }}>
          <Row className="justify-content-center">
            <Col md={8} lg={6}>
              <div
                className="login-signup-div"
                style={{
                  backgroundColor: "white",
                  padding: "30px",
                  borderRadius: "10px",
                  paddingLeft: "15%",
                  paddingRight: "15%",
                }}
              >
                <div className="text-center mb-4">
                  <img
                    src={userImg}
                    alt="User Profile"
                    style={{ width: "80px", borderRadius: "50%" }}
                  />
                </div>
                {alertVisibility && (
                  <Alert
                    variant="danger"
                    onClose={() => setAlertVisibility(false)}
                    dismissible
                  >
                    {errorMsg}
                  </Alert>
                )}
                <Form onSubmit={handleSubmit}>
                  <Form.Group
                    controlId="formBasicEmail"
                    style={{ marginBottom: "15px" }}
                  >
                    <Form.Control
                      className="text-center login-signup-input"
                      placeholder="First Name"
                      value={firstName}
                      name="firstName"
                      onChange={handleInputChange}
                      required
                     // style={{ color: "white" }}
                    />
                  </Form.Group>
                  <Form.Group
                    controlId="formBasicEmail"
                    style={{ marginBottom: "30px" }}
                  >
                    <Form.Control
                      className="text-center login-signup-input"
                      placeholder="Last Name"
                      value={lastName}
                      name="lastName"
                      onChange={handleInputChange}
                      required
                     // style={{ color: "white" }}
                    />
                  </Form.Group>
                  <Row>
                    <hr
                      style={{
                        marginTop: "0",
                        opacity: "0.2",
                        marginBottom: "30px",
                      }}
                    />
                  </Row>
                  <Form.Group
                    controlId="formBasicEmail"
                    style={{ marginBottom: "15px" }}
                  >
                    <Form.Control
                      className="text-center login-signup-input"
                      type="email"
                      placeholder="Your email address"
                      value={email}
                      name="email"
                      onChange={handleInputChange}
                      required
                     // style={{ color: "white" }}
                    />
                    <Form.Text
                      className="login-signup-comment text-muted text-center"
                      style={{ width: "100%", display: "grid" }}
                    >
                      This should be an email you can access after acquisition.
                    </Form.Text>
                  </Form.Group>
                  <Form.Group
                    controlId="formBasicPassword"
                    style={{ marginBottom: "25px" }}
                  >
                    <Form.Control
                      className="text-center login-signup-input"
                      type="password"
                      placeholder="Your Password"
                      value={password}
                      name="password"
                      onChange={handleInputChange}
                      // minLength={8}
                      required
                    />
                    <Form.Text
                      className="login-signup-comment text-muted text-center"
                      style={{ width: "100%", display: "grid" }}
                    >
                      Must include at least 8 characters and contain both
                      letters and numbers.
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                      Password must be at least 8 characters and contain both
                      letters and numbers.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    className="login-signup-button"
                    disabled={loading}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {loading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          style={{ marginRight: "5px" }}
                        />
                        &nbsp; Loading...
                      </>
                    ) : (
                      "Sign up"
                    )}
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container
        fluid
        className="justify-content-center"
        style={{
          marginTop: "1%",
          padding: "1%",
          backgroundColor: "white",
          width: "100%",
          bottom: "0",
        }}
      >
        <Footer />
      </Container>
      {/* <Modal centered show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Missing Package</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please choose one of the Buyer or Seller packages</p>
        </Modal.Body>
        <Modal.Footer>
          <a href="/buyers">
            <Button variant="primary">Buyer</Button>
          </a>
          <a href="/sellers">
            <Button variant="primary">Seller</Button>
          </a>
        </Modal.Footer>
      </Modal> */}
    </Container>
  );
};

export default Signup;
