export class FeaturesData {
    id: number;
    title: string;
    type: string;
    order: number;
    icon: string;
  }

  export class FeaturesDataMadatory {
    title: Boolean;
    type: Boolean;
    order: Boolean;
  }

