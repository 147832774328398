import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FormControl, TextField, TextareaAutosize } from "@mui/material";
import "./ContactUs.css";
import { SendContactFormRequest } from "../../interfaces/models/SendContactFormRequest";
import { SendContactForm } from "../../apis/EpendisisApi";

const ContactUs: React.FC = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    fullName: "",
    email: "",
    phone: "",
    message: "",
  });

  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const validate = () => {
    const newErrors: any = {};
    if (!formData.fullName) newErrors.fullName = "Full Name is required";
    if (!formData.email) newErrors.email = "E-mail address is required";
    if (!formData.phone) newErrors.phone = "Phone number is required";
    if (!formData.message) newErrors.message = "Message is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validate()) return;
    const request = new SendContactFormRequest(formData);
    try {
      const response = await SendContactForm(request);
      console.log("Form submitted successfully:", response);
      setSuccessMessage("Your message has been sent successfully!");

      // Optionally reset form after successful submission
      setFormData({
        fullName: "",
        email: "",
        phone: "",
        message: "",
      });
    } catch (error) {
      console.error("Error submitting form:", error);
      setSuccessMessage("");
    }
  };

  return (
    <Row className="justify-content-center contact-us-row" fluid>
      <Row className="text-center" style={{ paddingTop: "2%" }}>
        <h2 id="contact-us-heading">Contact us</h2>
      </Row>
      <Row className="text-center" style={{ height: "auto" }}>
        <p id="contact-us-paragraph">
          Have an investment in mind that you think we’d be a great fit for it?
          <p id="contact-us-paragraph">
            We’d love to know what you’re thinking.
          </p>
        </p>
      </Row>
      <Row style={{ marginTop: "25px", marginBottom: "25px" }}>
        <Col className="contact-us-col">
          <Row>
            <FormControl
              style={{ width: "100%", paddingLeft: "15%", paddingRight: "15%" }}
            >
              <TextField
                sx={{
                  input: { color: "white" },
                }}
                id="fullName"
                name="fullName"
                placeholder="Full Name"
                variant="filled"
                value={formData.fullName}
                onChange={handleChange}
                error={!!errors.fullName}
                helperText={errors.fullName}
                InputProps={{
                  classes: {
                    underline: "MuiInput-underline-white MuiInput-contact-us", // Apply white color to the animation line
                  },
                  inputProps: {
                    style: {
                      textAlign: "center",
                      paddingTop: "15px",
                      paddingBottom: "15px",
                    },
                  },
                }}
              />
            </FormControl>
          </Row>
          <Row>
            <FormControl
              style={{
                width: "100%",
                paddingLeft: "15%",
                paddingRight: "15%",
                marginTop: "25px",
              }}
            >
              <TextField
                sx={{
                  input: { color: "white" },
                }}
                id="email"
                name="email"
                placeholder="E-mail address"
                variant="filled"
                type="email"
                value={formData.email}
                onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email}
                InputProps={{
                  classes: {
                    underline: "MuiInput-underline-white MuiInput-contact-us", // Apply white color to the animation line
                  },
                  inputProps: {
                    style: {
                      textAlign: "center",
                      paddingTop: "15px",
                      paddingBottom: "15px",
                      backgroundColor: "none",
                    },
                  },
                }}
              />
            </FormControl>
          </Row>
          <Row>
            <FormControl
              style={{
                width: "100%",
                paddingLeft: "15%",
                paddingRight: "15%",
                marginTop: "25px",
              }}
            >
              <TextField
                sx={{
                  input: { color: "white" },
                }}
                id="phone"
                name="phone"
                placeholder="Phone number"
                variant="filled"
                type="number"
                value={formData.phone}
                onChange={handleChange}
                error={!!errors.phone}
                helperText={errors.phone}
                InputProps={{
                  classes: {
                    underline: "MuiInput-underline-white MuiInput-contact-us", // Apply white color to the animation line
                  },
                  inputProps: {
                    style: {
                      textAlign: "center",
                      paddingTop: "15px",
                      paddingBottom: "15px",
                      backgroundColor: "none",
                    },
                  },
                }}
              />
            </FormControl>
          </Row>
        </Col>
        <Col className="contact-us-col">
          <Row
            style={{ height: "100%", paddingLeft: "15%", paddingRight: "15%" }}
          >
            <TextareaAutosize
              className="textarea-contact-us"
              aria-label="empty textarea"
              placeholder="Write something..."
              name="message"
              value={formData.message}
              onChange={handleChange}
              style={{
                width: "100%",
                height: "100%",
                minHeight: "100px",
                borderRadius: "30px",
                padding: "12px 35px 12px 35px",
                outline: "none",
                border: "none",
              }}
            />
            {errors.message && (
              <span
                style={{
                  color: "#d32f2f",
                  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                  fontSize: "0.75rem",
                }}
                className="error"
              >
                {errors.message}
              </span>
            )}
          </Row>
        </Col>
      </Row>
      <Row
        className="justify-content-center"
        fluid
        style={{ marginTop: "21px", marginBottom: "61px" }}
      >
        <Button
          className="redefined-button-white"
          style={{ height: "70px", width: "209px" }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Row>
      {successMessage && (
        <Row className="justify-content-center">
          <p style={{ color: "green" }}>{successMessage}</p>
        </Row>
      )}
    </Row>
  );
};

export default ContactUs;
