export class RegisterRequest {
  name: string;
  email: string;
  password: string;
  package: string;
  roles: string;

  constructor(
    name: string,
    email: string,
    password: string,
    inputPackage: string,
    roles: string
  ) {
    this.name = name;
    this.email = email;
    this.password = password;
    this.package = inputPackage;
    this.roles = roles;
  }
}
