import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/Test.css";

function Test() {
  return (
    <div>
      sfdasd  
    </div>
  );
}

export default Test;
