import {
  Col,
  Container,
  Row,
} from "react-bootstrap";
import Footer from "../components/Footer/Footer";
import backgroundImg from "../assets/login-bg.png";
import HeaderMenu from "../components/HeaderMenu/HeaderMenu";
import { useEffect } from "react";

const ForgetPasswordEmailSuccess: React.FC = () => {

  useEffect(() => {
    document.title = "Forget Password";
  }, []);

  return (
    <Container
      fluid
      className="wrapper"
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: "0",
      }}
    >
      <div
        style={{
          backgroundImage: `url(${backgroundImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "100vh",
          display: "grid",
          flexDirection: "column",
          paddingBottom: "5%",
        }}
      >
        <HeaderMenu />
        <Container style={{ flex: "1", marginTop: "3%" }}>
          <Row className="justify-content-center">
            <Col md={8} lg={6}>
              <div
                className="login-signup-div"
                style={{
                  backgroundColor: "white",
                  padding: "30px",
                  borderRadius: "10px",
                  paddingLeft: "15%",
                  paddingRight: "15%",
                }}
              >
                <Row>
                  <h2 className="text-center">Check your email</h2>
                  <p className="text-center">
                  We have sent a password reset link to your email address. Please check your inbox and follow the instructions to reset your password. If you don't see the email, please check your spam or junk folder.
                  </p>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container
        fluid
        className="justify-content-center"
        style={{
          marginTop: "1%",
          padding: "1%",
          backgroundColor: "white",
          width: "100%",
          bottom: "0",
        }}
      >
        <Footer />
      </Container>
    </Container>
  );
};

export default ForgetPasswordEmailSuccess;
