import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";

import Footer from "../components/Footer/Footer";
import HeaderMenu from "../components/HeaderMenu/HeaderMenu";
import ContactUs from "../components/ContactUs/ContactUs";

function CookiesPolicy() {
  useEffect(() => {
    document.title = "Cookies Policy";
  }, []);

  return (
    <Container fluid>
      <Row
        style={{
          backgroundColor: "#242A49",
        }}
        className="justify-content-md-center"
      >
        <HeaderMenu />
      </Row>
      <Row
        style={{
          backgroundColor: "#FAFAFA",
          paddingLeft: "10%",
          paddingRight: "10%",
          paddingTop: "80px",
        }}
      >
        <Col>
          <p>Updated Date: 18/05/2023</p>
        </Col>
      </Row>
      {/* Cookies Policy Content */}
      <Row
        style={{
          backgroundColor: "#FAFAFA",
          paddingLeft: "10%",
          paddingRight: "10%",
          paddingTop: "20px",
        }}
      >
        <Col>
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
            }}
          >
            Introduction
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            This Cookie Policy explains how Ependisis.com (&quot;we,&quot;
            &quot;us,&quot; or &quot;our&quot;) uses cookies and similar
            tracking technologies when you visit our website, Ependisis.com (the
            &quot;Website&quot;). This policy also provides information on how
            you can manage and control the use of cookies.
          </p>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            What are Cookies
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            Cookies are small text files that are placed on your computer or
            mobile device when you visit a website. They are widely used to
            enhance your browsing experience, remember your preferences, analyze
            website performance, and provide personalized content.
          </p>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            Types of Cookies We Use
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "20px" }}
          >
            We use the following types of cookies on our Website:
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "20px" }}
          >
            <b>Essential Cookies</b>: These cookies are necessary for the
            functioning of our Website and enable you to navigate the site and
            use its features. They are typically set in response to actions you
            take, such as filling out forms or setting preferences.
          </p>

          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "20px" }}
          >
            <b>Analytics Cookies</b>: These cookies help us understand how
            visitors interact with our Website by collecting and reporting
            information anonymously. We use this data to improve the performance
            and usability of our Website.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "20px" }}
          >
            <b>Functional Cookies</b>: These cookies enable our Website to
            remember choices you make and provide enhanced functionality. For
            example, they may remember your language preference or the region
            you are in.
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            <b>Advertising Cookies</b>: We may use advertising cookies to
            deliver personalized advertisements that are relevant to your
            interests. These cookies collect information about your browsing
            habits and may be used by third-party advertisers to display ads on
            other websites.
          </p>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            Third-Party Cookies
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            We may also allow third-party service providers to place cookies on
            our Website for various purposes, including analytics and
            advertising. These cookies are subject to the respective privacy
            policies of these third-party providers.
          </p>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            Your Cookie Choices
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            You have the option to manage and control cookies through your
            browser settings. Most web browsers allow you to delete or block
            cookies, as well as set preferences for cookie usage. However,
            please note that disabling or blocking certain cookies may impact
            your experience on our Website.
          </p>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            Updates to this Cookie Policy
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            We may update this Cookie Policy from time to time to reflect
            changes in technology or legal requirements. We encourage you to
            review this policy periodically for any updates. The &quot;Effective
            Date&quot; at the top of this policy indicates when the latest
            version of the policy came into effect.
          </p>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              borderTop: "1px solid #ccc",
            }}
          />
          <h2
            className="heading"
            style={{
              fontWeight: "bold",
              fontSize: "30px",
              textAlign: "justify",
              marginTop: "40px",
            }}
          >
            Contact Us
          </h2>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "30px" }}
          >
            If you have any questions or concerns about our use of cookies or
            this Cookie Policy, please contact us at [contact email].
          </p>
          <p
            className="body-text"
            style={{ textAlign: "justify", marginBottom: "40px" }}
          >
            By continuing to use our Website, you consent to the use of cookies
            as described in this policy.
          </p>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <ContactUs />
      </Row>
      <Row
        className="justify-content-center"
        style={{ marginLeft: "1%", marginRight: "1%", marginTop: "1%" }}
      >
        <Footer />
      </Row>
    </Container>
  );
}

export default CookiesPolicy;
