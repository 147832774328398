import React, { useEffect, useState } from "react";
import { Fade } from "@mui/material";
import { Alert, Button, Container, Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import HeaderMenu from "../components/Legacy/HeaderMenu";
import EmailVerificationRedirectImage from "../assets/email-verification-redirect.png";
import EmailVerificationFailedImage from "../assets/email-verification-failed.png";
import { getUserInfo } from "../auth/AuthUtils";
import secureLocalStorage from "react-secure-storage";
import { UserInfo, addUserInfo } from "../redux/UserInfoReducer";
import {
  VerifyEmail as EpendisisVerifyEmail,
  GenerateVerification as EpendisisGenVerify,
  GetCustomerById,
} from "../apis/EpendisisApi";
import { useDispatch } from "react-redux";

function VerifyEmailAddress() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [email, setEmail] = useState<string>("");
  const [token, setToken] = useState<string | number | boolean | object | null>(
    ""
  );
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(true);
  const url = getCurrentURL();
  const last = url.split("/");

  const resendEmailVerification = async () => {
    try {
      await EpendisisGenVerify(email, token?.toString() ?? "");
    } catch {}
  };

  const handleVerification = async (userInfoInput: UserInfo, token: any) => {
    try {
      setShowError(false);
      await EpendisisVerifyEmail(last[last.length - 1]);
      const customer = await GetCustomerById(
        userInfoInput.id.toString(),
        token?.toString() ?? ""
      );
      if (customer?.status) {
        var updatedUserInfo = { ...userInfoInput };
        updatedUserInfo.status = customer.status;
        secureLocalStorage.setItem("user", JSON.stringify(updatedUserInfo));
        dispatch(addUserInfo(JSON.stringify(updatedUserInfo)));
      }
    } catch (error) {
      setShowError(true);
      await resendEmailVerification();
    }
  };

  function getCurrentURL() {
    return window.location.href;
  }

  const redirectOnboarding = () => {
    navigate("/onboarding"); 
  };

  useEffect(() => {
    document.title = "Verifying...";
    const fetchUserInfo = async () => {
      setLoading(true);
      const user = getUserInfo();
      const token = secureLocalStorage.getItem("token");
      setToken(token);
      if (user && token) {
        if (
          user.status === "PendingDetails" ||
          user.status === "PendingDocuments" ||
          user.status === "PendingApproval"
        ) {
          navigate("/onboarding");
        } else if (user.status === "Approved") {
          navigate("/marketplace");
        }

        await handleVerification(user, token);

        secureLocalStorage.removeItem("fromVerification");
        setUserInfo(user);
        setEmail(user.email);
      } else {
        if (last[last.length - 2].toLowerCase() === "verifyemail") {
          secureLocalStorage.setItem("fromVerification", last[last.length - 1]);
        }
        navigate("/login");
      }

      setLoading(false);
    };

    fetchUserInfo();
  }, []);

  return (
    <Container fluid>
      <Row
        style={{
          backgroundColor: "#242A49",
          marginBottom: "40px",
        }}
        className="justify-content-md-center"
      >
        <HeaderMenu />
      </Row>
      {loading ? (
        <Row className="justify-content-center" style={{ marginTop: "50px" }}>
          <Spinner animation="border" variant="primary" />
        </Row>
      ) : !showError ? (
        <>
          <Row className="justify-content-center">
            <img
              src={EmailVerificationRedirectImage}
              alt="Email Verification"
              style={{ maxWidth: "10%", marginTop: "50px" }}
            />
          </Row>
          <Row className="text-center" style={{ marginTop: "12px" }}>
            <h4 className="ff-lato" style={{ fontSize: "30px" }}>
              Your account has been verified
            </h4>
          </Row>
          <Row className="text-center" style={{ marginTop: "12px" }}>
            <p
              className="ff-lato"
              style={{ fontSize: "16px", marginBottom: "0" }}
            >
              Thank you for registering to Ependisis!
            </p>
            <p
              className="ff-lato"
              style={{ fontSize: "16px", marginTop: "10px" }}
            >
              Please click the button to proceed with the onboarding procedure.
            </p>
          </Row>
          <Row
            className="justify-content-center"
            style={{
              marginTop: "15px",
            }}
          >
            <Button
              variant="primary"
              className="blue-bg-white-col"
              style={{
                padding: "1%",
                paddingLeft: "4%",
                paddingRight: "4%",
                height: "auto",
                width: "auto",
              }}
              onClick={redirectOnboarding}
            >
              <span className="onboarding-button">Onboarding</span>
            </Button>
          </Row>
        </>
      ) : (
        <>
          <Row className="justify-content-center">
            <img
              src={EmailVerificationFailedImage}
              alt="Email Verification"
              style={{ maxWidth: "10%", marginTop: "50px" }}
            />
          </Row>
          <Row className="text-center" style={{ marginTop: "12px" }}>
            <h4 className="ff-lato" style={{ fontSize: "30px" }}>
              Verification Failed
            </h4>
          </Row>
          <Row className="text-center" style={{ marginTop: "12px" }}>
            <p
              className="ff-lato"
              style={{ fontSize: "16px", marginTop: "10px" }}
            >
              Please check your inbox for a new verification email.
            </p>
          </Row>
        </>
      )}
    </Container>
  );
}

export default VerifyEmailAddress;
